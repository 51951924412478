import { Component 				} from '@angular/core';

@Component({
	selector	: 'app',
	templateUrl	: './app.component.html',
	styleUrls	: ['./app.component.css']
})

/**
 * ----------------------------------------------------------
 *                     AppComponent
 * ----------------------------------------------------------
 *
 *  - Root App Component
 *
 * ----------------------------------------------------------
 */
export class AppComponent { }
