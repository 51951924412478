import { Injectable, Inject, Optional } from '@angular/core';
import { HttpHelper } from './http-helper.service';
import { ImageObject } from '../models/image.model';


@Injectable()
export class ImagesService {

    constructor(private readonly http: HttpHelper) {
    };

    get() {
        return this.http.get('api/images');
    }

    getImage(id: number) {
        return this.http.get('api/images/' + id);
    }

    save(image: ImageObject) {
        return this.http.put('api/images/' + image.hid, image);
    }

    add(image: ImageObject) {
        return this.http.post('api/images', image);
    }

    del(hid: Number) {
        return this.http.del('api/images/' + hid);
    }

}

