import { Component, Output, EventEmitter    } from '@angular/core';
import { FormBuilder, FormGroup             } from '@angular/forms';
import { MatDialog                          } from '@angular/material';
import { RegistriesService                  } from '../../services/registries.service';
import { CommunicationService               } from '../../services/communication.service';
import { MessageBusyService                 } from '../../services/message-busy.service';
import { SpinnerService                     } from '../../services/spinner.service';
import { ImagesService                      } from '../../services/images.service';
import { PrivacyDialogComponent             } from '../../dialogs/privacy-dialog/privacy-dialog.component';
import { NotificationDialogComponent        } from '../../dialogs/notification/notification.component';
import { ImageObject                        } from 'src/app/shared/models/image.model';
import { MessageError                       } from 'src/app/shared/models/message-error.model';
import { MessageAlert                       } from 'src/app/shared/models/message-alert.model';
import { mergeMap, catchError               } from 'rxjs/operators';
import { of, forkJoin                       } from 'rxjs';
import { Registry } from 'src/app/shared/models/registry.model';
import { Workshop } from 'src/app/shared/models/workshop.model';
import { FormsService } from '../../services/forms.service';
import { Payment } from '../../models/payment.model';
import * as moment from 'moment';
import * as _ from 'lodash';
import { PaymentsService } from '../../services/payments.service';

@Component({
    selector    : 'register-summary',
    templateUrl : './register-summary.component.html',
    styleUrls   : ['./register-summary.component.scss']
})

/**
 * ----------------------------------------------------------
 *                    RegisterSummaryComponent
 * ----------------------------------------------------------
 *
 * ----------------------------------------------------------
 */
export class RegisterSummaryComponent {
    @Output('done') done = new EventEmitter<boolean>();
    congressId           : number       = 6;
    form                 : FormGroup    = null;
    step4                : any          = null;
    submitPayment        : boolean      = false;
    disableSubmit        : boolean      = false;
    houseHoldPrincipalId : number       = 0;
    folio                : number       = 0;
    imageId              : number       = 0;
    selectedMainEvent    : string       = null;
    companionsArray      : any[]        = [];
    workshops            : Workshop[]   = [];

    fieldsToCopyFromHouseHold : string[] = [
        'phone',
        'city',
        'state',
        'requiresHomeAccomodation',
        'companions',
        'partialPayment',
        'paidInFull',
        'folio',
        'houseHoldPrincipalId'
    ]

    constructor( 
        public fb: FormBuilder,
        public dialog: MatDialog,
        private formsService: FormsService,
        private imageService: ImagesService,
        private spinnerService: SpinnerService, 
        private paymentsService: PaymentsService,       
        private registriesService: RegistriesService,
        private msgBusyService: MessageBusyService,
        private commService: CommunicationService
    ) {
        this.form = this.formsService.getNewRegistryForm();
        
        this.commService.watch("companionsArray").subscribe( (companionsArray) =>{
            this.companionsArray = companionsArray;
        });
        this.commService.watch("selectedMainEvent").subscribe( (selectedMainEvent) =>{
            this.selectedMainEvent = selectedMainEvent;
        });
        this.commService.watch("workshops").subscribe( (workshops) =>{
            this.workshops = workshops;
        });

        
        this.commService.watch("stepperIndex").subscribe( (stepperIndex) =>{
            if (stepperIndex === 3) {
                this.step4 = this.formsService.getSummaryStep4();
                this.form = this.step4.form;
            }
        });
    }

    //#region Private Methods

    private setWorkshopId(form: FormGroup) {
        var found = _.find(this.workshops, (x) => x.title === form.controls['workshop1'].value);
        if (found) {
            form.controls['workshops'].setValue(found.hid);
        }
    }

    /**
     * Especial Notes added depending on the Selected Event
     */
    private addEspecialNotes() {
        if (this.selectedMainEvent == 'Tallerista, Ponente o Miembro del Equipo Nacional') {
            var toSave = this.form.value.notes ? this.form.value.notes + ' - Tallerista, Ponente o Miembro del Equipo Nacional' : ' - Tallerista, Ponente o Miembro del Equipo Nacional';
            this.form.controls['notes'].setValue(toSave);
        } else if (this.selectedMainEvent == 'JOBI (Jovenes)'){
            var toSave = this.form.value.notes ? this.form.value.notes + ' - Solo JOBI' : 'Solo JOBI';
            this.form.controls['notes'].setValue(toSave);
        }
    }

    private setAmmountDue(age: number) {
        let result = 0;
        let today = moment();
        let deadline1 = moment('2019-10-17', 'YYYY-MM-DD');
        let deadline2 = moment('2019-10-31', 'YYYY-MM-DD');
        let adult    = 800;
        let jobi     = 600;
        let children = 200
        let baby     = 100;
        if (today.isBefore(deadline1)) {
            adult = 700;
            jobi = 550;
        } else if (today.isBefore(deadline2)) {
            adult = 750;
            jobi = 600;
        } 

        if (age <= 3) {
            result = baby;
        } else if (age <= 13) {
            result = children;
        } else if (age <= 18) {
            result = jobi;
        } else {
            result = adult;
        }
        return result;
    }

    /**
     * Step 1 - Save Receipt if found
     */
    private saveReceipt() {
        if (this.form.value.paymentReceipt1) {
            this.msgBusyService.start("Registro", "Guardando Recibo");
            let receipt     = new ImageObject();
            receipt.type    = this.form.value.paymentReceipt1Type;
            receipt.size    = this.form.value.paymentReceipt1Size;
            receipt.content = this.form.value.paymentReceipt1;
            return this.imageService.add(receipt).pipe(
                mergeMap((newImageId: number) => {
                    this.imageId = newImageId;
                    this.form.controls['paymentReceipt1ImageId'].setValue(newImageId);
                    return of(true);
                }));
         }
         return of(true);
    }

    /**
     * Step 2 - Save New Registry for House Holder
     */
    private addNewRegistry() {
        this.msgBusyService.start("Registro", "Guardando Registro");        
        this.form.controls['mainEvent'].setValue(this.selectedMainEvent);
        this.form.controls['ammountDue'].setValue(this.setAmmountDue(this.form.value.age));
        return this.registriesService.add(this.form.value).pipe(
            mergeMap((newRegistry: Registry) => {
               console.log(newRegistry); 
               this.msgBusyService.update("Registrando");
               this.houseHoldPrincipalId = newRegistry.hid;
               this.folio = newRegistry.folio;
               this.form.controls['folio'].setValue(newRegistry.folio);
               return of(true);
           }));
   }
   /**
    * Step 3 - Add Payment
    */
   private addPayment() {       
        if (this.form.value.paymentReceipt1) {
            let newPayment = new Payment();
            newPayment.imageId     = this.imageId;
            newPayment.courseId    = 0;
            newPayment.orderId     = 0;        
            newPayment.ammount     = parseFloat(this.form.value.paymentAmmount1);
            newPayment.congressId  = this.congressId;
            newPayment.folio       = this.folio;
            newPayment.createDate  = moment().format("YYYY-MM-DD");
            let receiptDate = this.form.value.paymentDate1 as Date;
            newPayment.modifyDate  = receiptDate.toISOString().slice(0,10);
            
            let cashPayment = this.form.value.paymentType;
            if (cashPayment === 'Pago en Efectivo el Dia del Evento') {
                newPayment.bank = '';
                newPayment.reference = '';
            } else {
                newPayment.bank = this.form.value.bankName;
                newPayment.reference = this.form.value.paymentReference1;
            }
            return this.paymentsService.add(newPayment);
        }
        return of(true);
   }
   /**
    * Step 4 - Add one registry per companion
    */
   private addCompanionRegistries(): any {
        if (this.companionsArray.length === 0) {
            return of(true)
        }
        let observables = [];
        this.msgBusyService.update("Registrando Acompañantes");
        _.each(this.companionsArray, (companion: any) => {
            // Copy Some Fields from Main Form
            _.each(this.fieldsToCopyFromHouseHold, (field) => {
                companion.form.controls[field].setValue(this.form.value[field]);
            })
            // Sets the Workshop ID selected
            this.setWorkshopId(companion.form);
            companion.form.controls['houseHoldOwner'].setValue(false);     
            companion.form.controls['houseHoldPrincipalId'].setValue(this.houseHoldPrincipalId);    
            companion.form.controls['ammountDue'].setValue(this.setAmmountDue(companion.form.value.age)); 
            observables.push(this.registriesService.add(companion.form.value));
        });
        return forkJoin(observables);
    }


    /**
     * Step 5 - Informs User The New Registry ID
     * and waits until it is confirmed
     */
    private showNewRegistryId() {
        const dialogRef = this.dialog.open(NotificationDialogComponent,
            {
                width: '350px', height: '400px', disableClose: true,
                data: { title: 'Confirmacion de Registro', message1: 'Tu registro ha sido exitoso.', message2 : '', folio: this.folio }
            });

        return dialogRef.afterClosed().pipe(mergeMap(() => of(true)));
    }

    /**
     * Step 6 - Sends Confirmation Email
     */
    private sendEmailRegistration() {
        this.msgBusyService.update("Enviando Correo de Registro");
        return this.registriesService.sendRegisteredEmail(this.houseHoldPrincipalId);
     }

     
    /**
     * Step 7 - Inform Sucess and Clean Stepper
     */
    private finish() {
        this.msgBusyService.done();
        const dialogRef = this.dialog.open(NotificationDialogComponent,
            {
                width: '350px', height: '400px', disableClose: true,
                data: { title: 'Confirmacion de Registro', message1: '', message2: 'Hemos enviado un correo a ' + this.form.value.email + ' con toda la informacion.', folio: this.folio }
            });

        return dialogRef.afterClosed();
    }


    //#endregion

    //#region Public Methods

    addMobileNotes() {
        var toSave = this.form.value.notes ? this.form.value.notes + ' - REGISTRO POR CELULAR - ' : '- REGISTRO POR CELULAR - ';
        this.form.controls['notes'].setValue(toSave);
    }

    register() {
        this.addEspecialNotes();
        this.setWorkshopId(this.form);

        this.disableSubmit = true;
        this.msgBusyService.start("Registro","Procesando");
        this.saveReceipt().pipe(                                // 1 - Save Receipt
            mergeMap(() => this.addNewRegistry()),              // 2 - Register Principal Account
            mergeMap(() => this.addPayment()),                  // 3 - Add Payment
            mergeMap(() => this.addCompanionRegistries()),      // 4 - Add One Registry per Companion
            mergeMap(() => this.showNewRegistryId()),           // 5 - Reports new Folio
            mergeMap(() => this.sendEmailRegistration()),       // 6 - Sends Confirmation Email
            mergeMap(() => this.finish()),                      // 7 - Success and Clean Up
            catchError((error) => {
                this.msgBusyService.error(new MessageError("Registro",error,""));
                this.disableSubmit = false;
                return of(false);
            })).subscribe(() => {                
                this.disableSubmit = false;
                this.msgBusyService.done();
                this.done.emit(true);
            });
    }

    //#endregion
}
