import { OnDestroy              } from '@angular/core';
import { DomSanitizer           } from '@angular/platform-browser';
import { AppInjector            } from '../../shared/services/app-injector.service';
import { TranslateService       } from '@ngx-translate/core';
import { takeUntil, mergeMap    } from 'rxjs/operators';
import { Subject, of, forkJoin  } from 'rxjs';
import { CommunicationService   } from '../services/communication.service';
import { SelectOption } from './select-option.model';

export class FormField implements OnDestroy {
    destroyed       : Subject<boolean>  = new Subject<boolean>();
    error           : string;
    placeholder     : string;
    placeholderKey  : string;
    errorKey        : string;
    targetField     : FormField = null;
    imgURI          : string;
    imgByteArray    : any;
    imgType         : string;
    imgSize         : number;
    trustedImg      : any = null;
    trustedPdf      : any = null;
    // Services Provided by the App Injector    
    sanitizer       : DomSanitizer;
    commService     : CommunicationService;
    translateService: TranslateService;
    
    constructor(
        public form: string, 
        public formControlName: string, 
        public type: string = 'text', 
        public required: boolean = true, 
        public rowSpan: number = 3, 
        public colSpan: number = 1, 
        public pattern: boolean = true, 
        public options: SelectOption[] = [], 
        public visible: boolean = true, 
        public onChange: any = null,
        public cols: number = 50, 
        public rows: number = 2
    ) {
            if (onChange) {
                this.selectionChange = onChange;
            }
            this.placeholderKey     = this.form.toUpperCase() + '_' + this.formControlName.toUpperCase();;
            this.errorKey           = this.placeholderKey + '_ERROR';        
            // Service to access local data
            const injector          = AppInjector.getInjector();
            this.commService        = injector.get(CommunicationService);
            this.translateService   = injector.get(TranslateService);
            this.sanitizer          = injector.get(DomSanitizer);
            // Default Language
            this.translateService.use(this.commService.get("language"));
            this.translate();

            this.commService.watch("language").pipe(
                takeUntil(this.destroyed)
            ).subscribe((val) => {
                if (val) {
                    this.translateService.use(val);
                    this.translate();
                }
            });
    }

    ngOnDestroy() {
        this.destroyed.next(true);
        this.destroyed.unsubscribe();
    }

    //#region Private Methods

    //#endregion

    //#region Public Methods

    /**
     * Retrieves the tanslated text based on current language
     */
    translate() {
        forkJoin(
            this.translateService.get([this.placeholderKey, this.errorKey])
        ).subscribe((res) => {
            this.placeholder = res[0][this.placeholderKey];
            this.error       = res[0][this.errorKey];
        })
    }    

    selectionChange() {
    }

    processImage() {
        if (this.imgType === "application/pdf") {
            this.trustedImg = null;
            this.dataURItoBlob();
            
        } else if (this.imgType === "image/jpeg" || this.imgType === "image/jpg" ){
            this.trustedPdf = null;
            this.sanitizePicture();
        }            
    }

    /**
     * Creates a Blob
     */
    dataURItoBlob() {
        this.imgByteArray = this.convertDataURIToBinary();
        let file = new Blob([this.imgByteArray], { type: this.imgType});
        this.trustedPdf = this.sanitizer.bypassSecurityTrustResourceUrl((URL.createObjectURL(file)));
    }
    
        /**
     * Converts URI to binary
     * @param dataURI 
     */
    convertDataURIToBinary() {
        var raw = window.atob(this.imgURI);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));

        for (var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }

    /**
     * Sanitizes a string as byte base64image
     */
    sanitizePicture() {
        this.trustedImg = this.sanitizer.bypassSecurityTrustUrl("data: " + this.imgType + "; base64, " + this.imgURI);
    }

    setContent(imgType: string, data: number[] = []) {
        this.imgType = imgType;
        this.imgURI = data.toString();
        this.processImage();
    }

    //#endregion
}
