import { Component, Inject                  } from '@angular/core';
import { Router                             } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA      } from '@angular/material';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig     } from '@angular/material';
import { Registry                           } from '../../../models/registry.model';
import { HttpHelper                         } from '../../../services/http-helper.service';
import { MessageBusyService                 } from '../../../services/message-busy.service';
import { MessageError                       } from '../../../models/message-error.model';
import { Workshop                           } from '../../../models/workshop.model';
import { CommunicationService               } from '../../../services/communication.service';
import { RegistriesService                  } from '../../../services/registries.service';
import { FormsService                       } from '../../../services/forms.service';
import { FormField                          } from '../../../models/form-field.model';
import * as _ from 'lodash';
import { WorkshopsService } from '../../../services/workshops.service';
import { SelectOption } from 'src/app/shared/models/select-option.model';

@Component({
    selector    : 'add-companion-dialog',
    templateUrl : './add-companion-dialog.component.html',
    styleUrls   : ['./add-companion-dialog.component.scss']
})

export class AddCompanionDialogComponent {  
    step            : any           = null;  
    mainRegistry    : Registry      = null;
    newRegistry     : Registry      = null;
    formGroup       : FormGroup     = null;
    fields          : FormField[]   = [];
    workshops       : Workshop[]    = [];
    ready           : boolean       = false; 
    
    fieldsToCopyFromHouseHold : string[] = [
        'phone',
        'city',
        'state',
        'requiresHomeAccomodation',
        'companions',
        'partialPayment',
        'paidInFull',
        'folio',
        'houseHoldPrincipalId'
    ]
     // Form
     companion_group = {
        firstName       : [''], 
        lastName        : [''], 
        age             : [''], 
        relationship    : [''],
        mainEvent       : [''],
        workshop1       : ['']
    }
    eventTypes      : SelectOption[]    = [
        new SelectOption('Congreso'), 
        new SelectOption('JOBI (Jovenes)'), 
        new SelectOption('Ninos (6 - adelante)'), 
        new SelectOption('Guarderia (2-5)'), 
        new SelectOption('Ninguno')
    ];
    relationships   : SelectOption[] = [
        new SelectOption('Esposo(a)'),
        new SelectOption('Hijo(a)'),
        new SelectOption('Abuelo(a)'),
        new SelectOption('otro')
    ];
    // Fields    
    firstName       : FormField;
    lastName        : FormField;
    age             : FormField;
    relationship    : FormField;
    workshop1       : FormField;
    mainEvent       : FormField;

    constructor(
        public dialogRef: MatDialogRef<AddCompanionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Registry,
        public fb: FormBuilder,
        public http: HttpHelper,
        public snackBar: MatSnackBar,
        private formsService: FormsService,
        private workshopService: WorkshopsService,
        private commService: CommunicationService,
        private msgBusyService: MessageBusyService,
        private registriesService: RegistriesService,
    ) {
        this.mainRegistry   = data;
        this.load();       
    }

    //#region Private Methods

    private load() {
        return this.workshopService.getByCongress(this.mainRegistry.congressId).subscribe((workshops: any[]) => {
            this.workshops = workshops;  
            this.createForm();          
            this.ready = true;
        })
    }
    
    private createForm() {  
        let workshopOptions = [];     
        _.each(this.workshops, (w) => {
            workshopOptions.push(new SelectOption(w.title));
        }) 
        this.formGroup = this.formsService.getNewRegistryForm();        
        this.firstName      = new FormField('form1', 'firstName'  );
        this.lastName       = new FormField('form1', 'lastName'   );
        this.age            = new FormField('form1', 'age'        );
        this.relationship   = new FormField('form1', 'relationship', 'select', true, 3, 1, false, this.relationships, true);
        this.mainEvent      = new FormField('form1', 'mainEvent', 'select',   true, 3, 1, false, this.eventTypes,true, this.onCompanionMainEventChange);
        this.workshop1      = new FormField('form1', 'workshop1', 'select',   true, 3, 3, false, workshopOptions, false);       
        this.mainEvent.targetField = this.workshop1;
        this.fields         = [this.firstName, this.lastName, this.age, this.relationship, this.mainEvent, this.workshop1];
        this.copyFieldsFromMainRegistry();        
    }

    private copyFieldsFromMainRegistry() {
        // Copy Some Fields from Main Form
        _.each(this.fieldsToCopyFromHouseHold, (field) => {
            this.formGroup.controls[field].setValue(this.mainRegistry[field]);
        })
        this.formGroup.controls['houseHoldOwner'].setValue(false);     
        this.formGroup.controls['houseHoldPrincipalId'].setValue(this.mainRegistry.hid);  
    }

    private setWorkshopId() {
        var found = _.find(this.workshops, (x) => x.title === this.formGroup.controls['workshop1'].value);
        if (found) {
            this.newRegistry.workshops = found.hid.toString();
        }
    }

    private onCompanionMainEventChange = (field: FormField, value: any) => {
        if (value === 'Congreso') {
            field.targetField.visible = true;
        } else {
            field.targetField.visible = false;  
            this.formGroup.controls['workshop1'].setValue('');  
        }
    };  

    private updateRegistryWithFormValues(){
        _.each(this.fields, (field: FormField) => {
            this.newRegistry[field.formControlName] = this.formGroup.get(field.formControlName).value;
        })
    }

    //#endregion

    //#region Public Methods

    close(): void {
        this.dialogRef.close(null);
    }

    save() {
        this.newRegistry = this.formGroup.value;
        this.setWorkshopId();
        this.updateRegistryWithFormValues();
        console.log(this.newRegistry);
        this.msgBusyService.start("Guardando");
        this.registriesService.add(this.newRegistry).subscribe((newRegistry) => {
            this.msgBusyService.done();
            this.dialogRef.close(newRegistry);
        });
    }

    //#endregion
}
