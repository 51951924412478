import { Injectable     } from '@angular/core';
import { Headers        } from '@angular/http';
import { HttpHelper     } from './http-helper.service';
import { of             } from 'rxjs';
import { mergeMap       } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable()
export class ReportsService {

    constructor(private readonly http: HttpHelper) {
    };

    /**
     * Converts URI to binary
     * @param dataURI 
     */
    private convertDataURIToBinary(URI) {
        var raw = window.atob(URI);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));

        for (var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }

    getWorkshopReport(congressId: number, workshopId: number ) {   
        let url = 'api/reports/workshops?congressId=' + congressId + '&workshopId=' + workshopId
        return this.http.post(url,{}).pipe( mergeMap((uri) =>of(this.convertDataURIToBinary(uri))));
    }    

    getAccomodationReport(congressId: number) {   
        let url = 'api/reports/accomodations?congressId=' + congressId;
        return this.http.post(url,{}).pipe( mergeMap((uri) =>of(this.convertDataURIToBinary(uri))));
    }    

    getRegistryReport(congressId: number, format: string = 'PDF') {
        let url = 'api/reports/registries?congressId=' + congressId + '&format=' + format;
        return this.http.post(url,{}).pipe( mergeMap((files: any) =>{
            let result = [];
            if (files.length === 0) return of([]);
            if (files.length > 100) {
                result.push(this.convertDataURIToBinary(files));
            } else {
                _.each(files, (file, index) => {
                    result.push(this.convertDataURIToBinary(file));
                })
            }
            return of(result);
        }));
    }  
}
