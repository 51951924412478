import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { MatDialog                              } from '@angular/material';
import { Router                                 } from '@angular/router';
import { LoginDialogComponent                   } from '../../dialogs/login/login.component';
import { CommunicationService                   } from '../../services/communication.service';
import { MessageBusyService                     } from '../../services/message-busy.service';
import { LoggerService                          } from '../../services/logger.service';
import { TranslateService                       } from '@ngx-translate/core';
import { takeUntil                              } from 'rxjs/operators';
import { Subject                                } from 'rxjs';
import * as _ from 'lodash';

@Component({
    selector    : 'header',
    templateUrl : './header.component.html',
    styleUrls   : ['./header.component.scss']
})

export class HeaderComponent {
    @Output() action = new EventEmitter<string>();
    @Input() set background (background: string) { this._background = background; }
    @Input() set color      (color: string)      { this._color = color;           }
    @Input() set opacity    (opacity: number)    { this._opacity = opacity;       }
    @Input() set scrolled   (value: boolean)     { this._scrolled = value;        }

    public currentDevice                = 'other';
    public destroyed: Subject<boolean>  = new Subject<boolean>();
    public _opacity: number;
    public _color: string;
    public _background: string;
    public _scrolled             = false;
    public scrollOnTop           = true;
    public language: any;

    constructor(
        private router: Router,
        public dialog: MatDialog,
        private translate: TranslateService,
        private loggerService: LoggerService,
        private commService: CommunicationService,
        private msgBusyService: MessageBusyService
    ) {
        
        this.language = this.commService.get("language");           
         // Watchers
         this.commService.watch("currentDevice").pipe(
            takeUntil(this.destroyed)
        ).subscribe((currentDevice) => {
            if (currentDevice !== undefined && currentDevice !== null) {      
                this.currentDevice= currentDevice;
            }
        });

         // Watchers
         this.commService.watch("scrollOnTop").pipe(
            takeUntil(this.destroyed)
        ).subscribe((scrollOnTop) => {
            if (scrollOnTop !== undefined && scrollOnTop !== null) {      
                this.scrollOnTop= scrollOnTop;
                console.log(this.scrollOnTop);
            }
        });
    }

    ngOnDestroy() {
        this.destroyed.next(true);
        this.destroyed.unsubscribe();
    }

    //#region Private Methods       
   
    // #endregion

    // #region Public Methods  	
    
    goTo(action: string) {
        if (action.indexOf('congress')>=0) {
            this.msgBusyService.start("Congreso","Cargando");
            setTimeout(() => {
                this.msgBusyService.done();
            }, 2000);
        }
        this.router.navigateByUrl(action);
    }

    setLanguage (lang: any) {
        this.language = lang;
        this.loggerService.logValue("Login","setLanguage", this.language);
        this.translate.use(this.language);
        this.commService.set("language", this.language);
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(LoginDialogComponent, {
            width: '350px',
            height:'450px'
          //data: {name: this.name, animal: this.animal}
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
        });
    }

    // #endregion   
   
}
