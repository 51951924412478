import { Component, NgZone, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MessageError                   } from '../../../shared/models/message-error.model';
import { CommunicationService           } from '../../../shared/services/communication.service';
import { takeUntil                      } from 'rxjs/operators';
import { Subject                        } from 'rxjs';

@Component({
    selector    : 'message-error',
    templateUrl : './message-error.component.html',
    styleUrls   : ['./message-error.component.scss']
})

export class MessageErrorComponent implements AfterViewInit, OnDestroy {
    @ViewChild('buttonClose', { read: ElementRef }) buttonClose: ElementRef;
    public msgError: MessageError;
    public destroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private zone: NgZone,
        private commService: CommunicationService
    ) {
        this.msgError = this.commService.get("msgError");
        this.commService.watch("msgError").pipe(
            takeUntil(this.destroyed)
        ).subscribe((val) => this.updateValue("msgError", val));
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.buttonClose.nativeElement.focus();
        }, 750);
    }

    ngOnDestroy() {
        this.destroyed.next(true);
        this.destroyed.unsubscribe();
    }

    // #region Private Methods

   /**
    * Updates the value and forces a detect change
    * running the update through Angular ngZone
    */
    private updateValue(name, val) {
        if (val !== undefined && val !== null) {
            this.zone.run(() => {
                this[name] = val;

            });
        }
    }

    // #endregion

    // #region Public Methods

    public close() {
        this.msgError.clean();
        this.commService.get("overlayRef").dispose();
        this.commService.set("overlayRef",null);
    }

    // #endregion
}

