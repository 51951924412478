import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'privacy-dialog',
    templateUrl: './privacy-dialog.component.html',
    styleUrls: ['./privacy-dialog.component.scss']
})

export class PrivacyDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<PrivacyDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any)
    {     
    }

    cancel(): void {
        this.dialogRef.close(null);
    }

    ok() {
        this.dialogRef.close(true);
    }
}
