import { Injectable } from '@angular/core';
import { HttpHelper } from './http-helper.service';
import { Congress   } from '../models/congress.model';

@Injectable()
export class CongressService {

    constructor(private readonly http: HttpHelper) {
    };

    get() {
        return this.http.get('api/congresses');
    }

    getById(id: number) {
        return this.http.get('api/congresses/' + id);
    }

    save(congress: Congress) {
        return this.http.put('api/congresses/' + congress.hid, congress);
    }

    add(congress: Congress) {
        return this.http.post('api/congresses', congress);
    }

    del(hid: Number) {
        return this.http.del('api/congresses/' + hid);
    }

    getByTitle(congress: Congress) {
        return this.http.post('api/congresses/ByTitle', congress);
    }

}

