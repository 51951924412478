import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})

export class NotificationDialogComponent {
    title = '';
    message1 = '';
    message2 = '';
    folio = null;

    constructor(
        public dialogRef: MatDialogRef<NotificationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any)
    {
        this.title = data.title;
        this.message1 = data.message1;
        this.message2 = data.message2;
        this.folio = data.folio? data.folio : null;
    }

    ok() {
        this.dialogRef.close(true);
    }
}
