import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss']
})

export class ConfirmDialogComponent {
    title = '';
    message = '';

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any)
    {
        this.title = data.title;
        this.message = data.message;
    }

    cancel(): void {
        this.dialogRef.close(null);
    }

    ok() {
        this.dialogRef.close(true);
    }
}
