import { Directive, HostListener, ElementRef, OnInit, OnDestroy, AfterContentInit, AfterContentChecked        } from '@angular/core';
import { CommunicationService                       } from '../services/communication.service';
import { LoggerService                              } from '../services/logger.service';
@Directive({
    selector: '[scrollOnTop]'
})

export class ScrollOnTopDirective implements OnInit, OnDestroy{
    private scrollTop = 0;
    private currentPage = 0;
    private nativeElement = null;
    private body = null;

    constructor(
        private elem: ElementRef,
        private loggerService: LoggerService,
        private commService: CommunicationService
    ) {
        //this.nativeElement = this.elem.nativeElement;
    }    
        
    ngOnInit() {
        window.addEventListener('scroll', this.scroll, true); //third parameter
        setTimeout(() => {            
            this.body = document.getElementsByName('body');
        },250)
    }

    ngOnDestroy() {
        window.removeEventListener('scroll', this.scroll, true);
    }
    
    private verifyPageNumber() {
        var page = Math.floor(window.scrollY / window.innerHeight);
        if (this.currentPage !== page) {     
            this.currentPage = page       
            this.loggerService.logValue("scrollOnTop Directive", "Page Change", this.currentPage)  ;   
            this.commService.set("currentPage", this.currentPage);
        }
    }

    //@HostListener('scroll', ['$event'])
    //onWindowScroll(e: any) 
    scroll = (e: any): void => {
        var currentVal = window.scrollY;
        if (this.scrollTop === 0 && currentVal > 0) {
            this.loggerService.logValue("scrollOnTop Directive", "Scroll on Top", false)  ;   
            this.commService.set("scrollOnTop", false);
        } else if (this.scrollTop > 0 && currentVal === 0) {
            this.loggerService.logValue("scrollOnTop Directive", "Scroll on Top", true)  ;   
            this.commService.set("scrollOnTop", true);
        }
        this.scrollTop = currentVal;
        this.verifyPageNumber();
    }
}
