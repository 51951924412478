import { Component                          } from '@angular/core';
import { MatDialogRef                       } from '@angular/material';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpHelper                         } from '../../../services/http-helper.service';
import { CommunicationService               } from '../../../services/communication.service';
import { MessageBusyService                 } from '../../../services/message-busy.service';
import { User                               } from '../../../models/user.model';

@Component({
    selector    : 'edit-password-dialog',
    templateUrl : './edit-password-dialog.component.html',
    styleUrls   : ['./edit-password-dialog.component.scss']
})

export class EditPasswordDialogComponent {
    showForm = true;
    changeForm: FormGroup;
    user: User;

    constructor(
        public fb: FormBuilder,
        public http: HttpHelper,
        public commService: CommunicationService,
        private msgBusyService: MessageBusyService,
        public dialogRef: MatDialogRef<EditPasswordDialogComponent>
    ) {
        this.user = this.commService.get("user");
        this.changeForm = this.fb.group({
            password: ['', [Validators.required]]
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    go(event: Event) {
        const data: any = {
            password: this.changeForm.value.password
        };
        console.log(data);
        this.msgBusyService.start("Creando");
        this.http.put('api/login/' + this.user.hid, data).subscribe(
            response => {
                this.msgBusyService.done();
                //this.snackBar.open("Cambio Exitoso", "Ok", config);
                this.changeForm.reset();
                this.showForm = false;
                setTimeout(() => this.showForm = true);
                this.dialogRef.close(null);
            },
            error => {
                console.log(error);
                //this.snackBar.open("Erro: ", error, config);
            });
    }
}
