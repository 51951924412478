import { Injectable, NgZone     } from '@angular/core';
import { ComponentPortal        } from '@angular/cdk/portal';
import { Overlay                } from '@angular/cdk/overlay';
import { CommunicationService   } from './communication.service';
import { LoggerService          } from './logger.service';
import { MessageBusy            } from '../models/message-busy.model';
import { MessageError           } from '../models/message-error.model';
import { MessageAlert           } from '../models/message-alert.model';
import { MessageBusyComponent   } from '../components/message-busy/message-busy.component';
import { MessageErrorComponent  } from '../components/message-error/message-error.component';
import { MessageAlertComponent  } from '../components/message-alert/message-alert.component';

/**
 * ----------------------------------------------------------
 *                     MessageBusy Service
 * ----------------------------------------------------------
 *
 * Used to turn on/off/update the message busy component
 *
 * This service provides the functionality for:
 *
 *    - start
 *    - update
 *    - error
 *    - alert
 *    - done
 *    - cleanMsg
 *    - cleanErr
 *    - cleanAlert
 *    - setTimeout
 *
 * ----------------------------------------------------------
 */
@Injectable()
export class MessageBusyService {
    private timer: any;
    private timeout: number = 60000;
    public overlayRef: any;
    public msg: MessageBusy;
    public err: MessageError;
    public alr: MessageAlert;

    /**
   * Initializes a new instance of the MessageBusyService
   */
    constructor(
        private zone: NgZone,
        private overlay: Overlay,
        private loggerService: LoggerService,
        private commService: CommunicationService
    ) {
        this.msg = new MessageBusy();
    }

    // #region Private Methods

    /**
     * Starts internal Timer
     * Emits an Error Change if times out
     */
    private setTimer() {
        this.cancelTimer();
        this.timer = setTimeout(() => {
            this.error(new MessageError("Error", "Operation Timed Out", "Please try again"));            
        }, this.timeout);
    }

    /**
     * Cancels internal timer if exists
     */
    private cancelTimer() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    private removeOverlay() {
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = null;
            this.commService.set("overlayRef", null);
        }
    }

    private addOverlay(typeOfComponent: any) {
        this.overlayRef = this.commService.get("overlayRef");
        if (!this.overlayRef) {
            this.overlayRef = this.overlay.create({
                positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                hasBackdrop: true
            });
            //this.zone.run(() => {
                setTimeout(() => {
                    if (this.overlayRef) {
                        this.overlayRef.attach(new ComponentPortal(typeOfComponent));
                    }
                })
            //});
        }       
    }

    // #endregion

    // #region Public Methods

    /**
    * Updates Message Object and emits a change to the observable
    * It sets the internal timer to keep an eye on timeouts
    * @param title as string
    * @param details as string optional
    */
    public start(title: string, details: string = "") {
        if (this.err && this.err.isActive()) return;
        this.setTimer();
        this.msg.set(title,details);
        this.addOverlay(MessageBusyComponent); 
        this.commService.set("overlayRef", this.overlayRef);
        this.commService.set("msgBusy", this.msg);
    }

    /**
     * Updates the Message Object and emits a change to the observable
     * @param details
     */
    public update(details) {
        this.msg.update(details);
        this.commService.set("msgBusy", this.msg);
    }   

    /**
     * Updates Error Object and emits a change to the observable
     * Cleans any regular message
     * @param error as MessageError
     */
    public error(error: MessageError) {
        this.cleanMsg();
        this.err = error;
        this.removeOverlay();
        this.commService.set("msgError", this.err);
        this.addOverlay(MessageErrorComponent);
        this.commService.set("overlayRef", this.overlayRef);
    }

    /**
     * Updates Alert Object and emits a change to the observable
     * Cleans any regular message
     * @param alert as MessageEror
     */
    public alert(alert: MessageError) {
        if (this.err && this.err.isActive()) return;
        this.cleanMsg();
        this.alr = alert;
        this.removeOverlay();
        this.commService.set("msgAlert", this.alr);
        this.addOverlay(MessageAlertComponent);                 
        this.commService.set("overlayRef", this.overlayRef);
    }

    /**
     * Hides MsgBusy for regular messages
     */
    public done() {
        this.cancelTimer();
        if ((this.err && this.err.isActive()) || (this.alr && this.alr.isActive())) return;
        this.zone.run(() => this.cleanMsg());
    }

    /**
     * Cleans Message Object and emits a change to the observable
     */
    public cleanMsg() {
        this.msg.clean();
        this.commService.set("msgBusy", this.msg);
        this.removeOverlay();
    }

    /**
     * Cleans Error Object and emits a change to the observable
     */
    public cleanErr() {
        this.err.clean();
        this.commService.set("msgError", this.err);
        this.removeOverlay();
    }

    /**
     * Cleans Alert Object and emits a change to the observable
     */
    public cleanAlert() {
        this.alr.clean();
        this.commService.set("msgAlert", this.alr);
        this.removeOverlay();
    }

    /**
     * Configures the expiration time for a global timer
     * When the timer expires, the MsgBusy will be stopped
     * and report a TimeOut Error Message
     * This is usually configured by the Server
     * Default value is 5 minutes
     * @param value
     */
    public setTimeout(value: number = 5) {
        this.timeout = value * 60000;
        this.loggerService.logValue("MsgBusy Service", "Set Timeout", this.timeout);
    }

    // #endregion
}
