import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router                         } from '@angular/router';
import { DomSanitizer                   } from '@angular/platform-browser';
import { MatDialog                      } from '@angular/material';
import { EditPasswordDialogComponent    } from '../../dialogs/edit/edit-password-dialog/edit-password-dialog.component';
import { CommunicationService           } from '../../services/communication.service';
import { User                           } from '../../models/user.model';
import { takeUntil                      } from 'rxjs/operators';
import { Subject                        } from 'rxjs';
import * as _ from 'lodash';

@Component({
    selector    : 'admin-header',
    templateUrl : './admin-header.component.html',
    styleUrls   : ['./admin-header.component.scss']
})

export class AdminHeaderComponent {
    @Output() action = new EventEmitter<string>();
    @Input() set background (background: string) { this._background = background; }
    @Input() set color      (color: string)      { this._color = color;           }
    @Input() set opacity    (opacity: number)    { this._opacity = opacity;       }
    @Input() set scrolled   (value: boolean)     { this._scrolled = value;        }

    currentDevice                = 'other';
    destroyed: Subject<boolean>  = new Subject<boolean>();
    _opacity: number;
    _color: string;
    _background: string;
    _scrolled             = false;
    scrollOnTop           = true;
    language: any;
    user: User = null
    constructor(
        public dialog: MatDialog,
        private router: Router,
        private sanitizer: DomSanitizer,
        private commService: CommunicationService
    ) {
        this.user = commService.get("user");
        this.sanitizePicture();
    }

    goTo(id: string) {
        this.router.navigateByUrl(id);
    }

    goToDashboard(id: string) {
        //this.commService.set("");
        this.router.navigateByUrl(id);

    }

    logoOut() {
        this.commService.set("user", null);
        this.router.navigateByUrl('welcome');
    }

    sanitizePicture() {
        this.user.trustedPicture = this.sanitizer.bypassSecurityTrustUrl("data: image/jpeg; base64, " + this.user.picture);
    }

    mailPassword() {
        window.open('https://mail11.mymailcheap.com/accounts/login/', '');
    }

    changePassword() {
        const dialogRef = this.dialog.open(EditPasswordDialogComponent, { width: '350px', height: '425px' });
    }
    
}
