import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommunicationService                   } from '../../services/communication.service';
import { FormField                              } from '../../models/form-field.model';
import * as _ from 'lodash';

@Component({
    selector    : 'register-step',
    templateUrl : './register-step.component.html',
    styleUrls   : ['./register-step.component.scss']
})

export class RegisterStepComponent  {
    @Input() step     : any; 
    @Input() cols     : number;
    @Input() subForms : any[];
    @Input() fields   : FormField[];
    @Input() prev     : boolean = true;
    @Input() next     : boolean = true;
    @Output() prevStep      = new EventEmitter<boolean>();
    @Output() nextStep      = new EventEmitter<boolean>();

    constructor( private commService: CommunicationService) { }

    //#region Private Methods

    // #endregion

    // #region Public Methods  	

    subFormsValid() {
        let allFormsValid = true;
        _.each(this.subForms, (subForm) => {
            if (allFormsValid) {
                allFormsValid = subForm.form.status === "VALID";
            }            
        })
        return allFormsValid;
    }

    markSubFormsAsTouched() {
        _.each(this.subForms, (subForm) => {
            _.each(subForm.form.controls, c => c.markAsTouched());
        })
    }
    
    /**
     * Saves the changes before moving to prev step
     */
    prevClicked() {
        if (this.subFormsValid()) {
            this.commService.set(this.step.formName, this.step.form);
            this.prevStep.emit();
        } else {
            this.markSubFormsAsTouched();
        }
    }

    /**
     * Saves the changes before moving to next step
     */
    nextClicked() {
        if (this.subFormsValid()) {
            this.commService.set(this.step.formName, this.step.form);
            this.nextStep.emit();
        } else {
            this.markSubFormsAsTouched();
        }
    }

    // #endregion
}
