import { Injectable } from '@angular/core';
import { HttpHelper } from './http-helper.service';
import { Registry   } from '../models/registry.model';
import { mergeMap   } from 'rxjs/operators';
import { of         } from 'rxjs';

@Injectable()
export class RegistriesService {

    constructor(private readonly http: HttpHelper) {
    };

    get() {
        return this.http.get('api/registries');
    }
    
    getById(id: number) {
        return this.http.get('api/registries/' + id);
    }

    getByCongresId(id: number) {
        return this.http.get('api/registries/' + id + '/ByCongress');
    }

    getByWorkshopId(congressId: number, workshopId) {
        return this.http.get('api/registries/' + congressId + '/ByWorkshop?workshopId=' + workshopId);
    }

    save(registeredUser: Registry) {
        return this.http.put('api/registries/' + registeredUser.hid, registeredUser);
    }

    add(registeredUser: Registry) {
        return this.http.post('api/registries', registeredUser);
    }

    del(hid: Number) {
        return this.http.del('api/registries/' + hid);
    }

    getByEmail(congressId: number, data: any) {
        return this.http.post('api/registries/' + congressId + '/ByEmail', data);
    }

    sendRegisteredEmail(id: number) {
        return this.http.get('api/registries/sendRegisteredEmail/' + id); 
    }

    verifyIfEmailAlreadyRegistered(congressId: number, email: string) {
        return this.getByEmail(congressId, { email: email} ).pipe(
            mergeMap((response) => of(response !== null)));

    }
}

