export class SelectOption { 
    title: string;
    desc : string;
    disabled: boolean;

    constructor(title: string) {
        this.title = title;
        this.desc = "";
        this.disabled = false;
    }
}
