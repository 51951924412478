import { Component                          } from '@angular/core';
import { MatDialogRef                       } from '@angular/material';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { WorkshopsService                   } from '../../../services/workshops.service';
import { MessageBusyService                 } from '../../../services/message-busy.service';

@Component({
    selector    : 'add-workshop-dialog',
    templateUrl : './add-workshop-dialog.component.html',
    styleUrls   : ['./add-workshop-dialog.component.scss']
})

export class AddWorkshopDialogComponent {
    showForm = true;
    newWorkshopForm: FormGroup = this.fb.group({ title: ['', [Validators.required]] });

    constructor(        
        public fb: FormBuilder,
        private workshopsService: WorkshopsService,
        private msgBusyService: MessageBusyService,
        private dialogRef: MatDialogRef<AddWorkshopDialogComponent>,
    ) {
        
    }

    close(): void {
        this.dialogRef.close();
    }

    go(event: Event) {
        const data: any = {
            title: this.newWorkshopForm.value.title,
        };
        console.log(data);
        this.msgBusyService.start("Creando");
        this.workshopsService.getByTitle(data).subscribe(
            response => {
                this.msgBusyService.done();
                if (response === null) {
                    this.dialogRef.close(data.title);
                } else {
                //     let config = new MatSnackBarConfig();
                //     config.extraClasses = ['custom-class'];
                //     config.duration = 5000
                //     this.snackBar.open("Error", "El Taller ya Existe", config);
                }

            },
            error => {
                console.log(error);
            });
    }
}
