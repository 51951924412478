import { Component, Inject                  } from '@angular/core';
import { Router                             } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA      } from '@angular/material';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig     } from '@angular/material';
import { Registry                           } from '../../../models/registry.model';
import { HttpHelper                         } from '../../../services/http-helper.service';
import { MessageBusyService                 } from '../../../services/message-busy.service';
import { MessageError                       } from '../../../models/message-error.model';
import { Workshop                           } from '../../../models/workshop.model';
import { CommunicationService               } from '../../../services/communication.service';
import { RegistriesService                  } from '../../../services/registries.service';
import { FormsService                       } from '../../../services/forms.service';
import { FormField                          } from '../../../models/form-field.model';
import * as _ from 'lodash';
import { WorkshopsService } from '../../../services/workshops.service';

@Component({
    selector    : 'edit-registry-dialog',
    templateUrl : './edit-registry-dialog.component.html',
    styleUrls   : ['./edit-registry-dialog.component.scss']
})

export class EditRegistryDialogComponent {    
    registry    : Registry      = null;
    formGroup   : FormGroup     = null;
    fields      : FormField[]   = [];
    workshops   : Workshop[]    = [];
    ready       : boolean       = false; 

    constructor(
        public dialogRef: MatDialogRef<EditRegistryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Registry,
        public fb: FormBuilder,
        public http: HttpHelper,
        public snackBar: MatSnackBar,
        private formsService: FormsService,
        private workshopService: WorkshopsService,
        private commService: CommunicationService,
        private msgBusyService: MessageBusyService,
        private registriesService: RegistriesService,
    ) {
        this.registry   = data;
        let step1       = this.formsService.getNewRegistryFormStep1();
        this.fields     = _.clone(step1.fields);
        this.fields.push(_.clone(this.formsService.requiresHomeAccomodation));
        this.formGroup  = _.clone(step1.form);
        this.formGroup.addControl('requiresHomeAccomodation',new FormControl(''));
        this.setWorkshopFieldVisible();
        this.load();       
    }

    //#region Private Methods

    private setWorkshopFieldVisible() {
        if (this.registry.workshops != "") {
            var found = _.find(this.fields, (x) => x.formControlName === 'workshop1');
            if (found){
                found.visible = true;
            }
        }
    }

    private load() {
        return this.workshopService.getByCongress(this.registry.congressId).subscribe((workshops: any[]) => {
            this.workshops = workshops; 
            this.setFormValues();
            this.ready = true;
        })
    }

    private setWorkshopId() {
        var found = _.find(this.workshops, (x) => x.title === this.formGroup.controls['workshop1'].value);
        if (found) {
            this.registry.workshops = found.hid.toString();
        }
    }

    private setFormValues(){
        _.each(this.fields, (field: FormField) => {
            this.formGroup.controls[field.formControlName].setValue(this.registry[field.formControlName]);            
        })        
        var found = _.find(this.workshops, (x) => x.hid === parseInt(this.registry.workshops));
        if (found) {
            this.formGroup.controls['workshop1'].setValue(found.title);
        }
    }

    private updateRegistryWithFormValues(){
        _.each(this.fields, (field: FormField) => {
            this.registry[field.formControlName] = this.formGroup.get(field.formControlName).value;
        })
    }

    //#endregion

    //#region Public Methods

    close(): void {
        this.dialogRef.close();
    }

    save() {
        this.setWorkshopId();
        this.updateRegistryWithFormValues();
        console.log(this.registry);
        this.msgBusyService.start("Guardando");
        this.registriesService.save(this.registry).subscribe(() => {
            this.msgBusyService.done();
            this.dialogRef.close();
            this.commService.set("selectedRegistry", this.registry);
        });
    }

    //#endregion
}
