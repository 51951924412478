import { Injectable } from '@angular/core';
import { HttpHelper } from './http-helper.service';
import { Workshop   } from '../models/workshop.model';

@Injectable()
export class WorkshopsService {

    constructor(private readonly http: HttpHelper) {
    };

    get() {
        return this.http.get('api/workshops');
    }

    save(workshop: Workshop) {
        return this.http.put('api/workshops/' + workshop.hid, workshop);
    }

    add(workshop: Workshop) {
        return this.http.post('api/workshops', workshop);
    }

    del(hid: Number) {
        return this.http.del('api/workshops/' + hid);
    }

    getByTitle(workshop: Workshop) {
        return this.http.post('api/workshops/ByTitle', workshop);
    }

    getByCongress(id: number) {
        return this.http.get('api/workshops/ByCongress/' + id);
    }

    
    getCounters(id: number) {
        return this.http.get('api/workshops/counters/' + id);
    }
}

