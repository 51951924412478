import { Component                          } from '@angular/core';
import { MatDialogRef                       } from '@angular/material';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig     } from '@angular/material';
import { HttpHelper                         } from '../../services/http-helper.service';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordDialogComponent {
    showForm = true;
    resetForm: FormGroup;

    constructor(public dialogRef: MatDialogRef<ResetPasswordDialogComponent>, public fb: FormBuilder, public http: HttpHelper, public snackBar: MatSnackBar) {
        this.resetForm = this.fb.group({
            email: ['', [Validators.required]],
            password: ['', [Validators.required]]
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    login(event: Event) {
        const data: any = {
            email: this.resetForm.value.email,
            password: this.resetForm.value.password
        };
        console.log(data);
        this.http.post('api/login', data).subscribe(
            response => {
                let config = new MatSnackBarConfig();
                //config.extraClasses = ['custom-class'];
                config.duration = 5000
                this.snackBar.open("Message Sent", "Ok", config);
                this.resetForm.reset();
                this.showForm = false;
                setTimeout(() => this.showForm = true);

            },
            error => {
                console.log(error);
                let config = new MatSnackBarConfig();
                //config.extraClasses = ['custom-class'];
                config.duration = 5000
                this.snackBar.open("Message Not Sent", error, config);
            });
    }
}
