import { Injectable } from '@angular/core';
import { HttpHelper } from './http-helper.service';
import { Instructor } from '../models/instructor.model';
import { Zone } from '../models/zone.model';
import { SubZone } from '../models/subZone.model';

@Injectable()
export class InstructorsService {

    constructor(private readonly http: HttpHelper) {
    };

    get() {
        //?countryCode=mx&underSupervision=false
        let params = {
            countryCode : 'mx', 
            underSupervision : false,
            picture: false
        }
        return this.http.get('https://www.appliedprogramming.io:9000/api/billings/public/instructors',{ params: params});
    }
}

