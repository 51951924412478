import { Injectable     } from '@angular/core';
import { DefaultsService} from './defaults.service';

/**
 * Logger Service
 */
@Injectable()
export class LoggerService  {
    private debug = false;

    /**
     * Initializes a new instance of the DefaultsService
     * Read the logger default value to validate if debug
     * statements should be printed or not
     * Errors and Warnings are always shown
     * @param DefaultsService
     */
    constructor(
        private defaultsService: DefaultsService
    ) {
        this.debug = this.defaultsService.defaults.debugMode;
    }

    //#region Private Methods

    /**
     * Add padding chars to the left of the string
     * @param text
     * @param char
     * @param size
     * @return string
     */
    private paddingLeft(text: string, char: string, size: number): string {
        return (String(char).repeat(size) + text).substr(text.length, size);
    }

    /**
     * Add padding chars to the right of the string
     * @param text
     * @param char
     * @param size
     * @return string
     */
    private paddingRight(text: string, char: string, size: number): string {
        return (text + String(char).repeat(size) ).substr(0, size);
    }


    /**
     * Returns a formatted timestamp
     * i.e. "2018.09.04T12:56:52.408"
     * @return string
     */
    private getTimeTimeStamp(): string {
        var today = new Date();
        var toPrint = today.getFullYear() + "." + this.paddingLeft(today.getMonth().toString(), "0", 2) + "." + this.paddingLeft(today.getDay().toString(), "0", 2);
        toPrint += "T" + this.paddingLeft(today.getHours().toString(), "0", 2) + ":" + this.paddingLeft(today.getMinutes().toString(), "0", 2);
        toPrint += ":" + this.paddingLeft(today.getSeconds().toString(), "0", 2) + "." + this.paddingLeft(today.getMilliseconds().toString(),"0",3) +" - ";
        return toPrint;
    }

    //#endregion

    //#region Public Methods

     /**
     * Prints a start section in the console if debug is turned ON
     * @param msg
     */
    public title(msg: any) {
        if (this.debug) {
            console.log("--------------------------------------");
            console.log(msg);
            console.log("--------------------------------------");
        }
    }

    /**
     * Prints a single line start section in the console if debug is turned ON
     * @param msg
     */
    public startSingle(msg: any) {
        if (this.debug) {
            console.log(this.paddingRight(this.paddingLeft(msg + " - START","-",50 + msg.length/2+4), "-",100));
        }
    }

    /**
     * Prints a single line end section in the console if debug is turned ON
     * @param msg
     */
    public endSingle(msg: any) {
        if (this.debug) {
            console.log(this.paddingRight(this.paddingLeft(msg + " - END", "-", 50 + msg.length/2+4), "-", 100));
        }
    }

    /**
     * Prints to the console a formatted title, key and value if debug is turned ON
     * @param title
     * @param key
     * @param value
     */
    public logValue(title: string, key: string, value: any) {
        if (this.debug) {
            var toPrint = this.getTimeTimeStamp();
            toPrint += this.paddingRight(title, " ", 20) + " - ";
            toPrint += this.paddingRight(key, " ", 20) + " : "
            toPrint += value;
            console.log(toPrint);
        }
    }

    /**
     * Prints to the console a formatted title and value if debug is turned ON
     * @param title
     * @param value
     */
    public logMessage(title: string, value: string) {
        if (this.debug) {
            var toPrint = this.getTimeTimeStamp();
            toPrint += this.paddingRight(title, " ", 20) + " - ";
            toPrint += value;
            console.log(toPrint);
        }
    }

     /**
     * Prints to the console if debug is turned ON
     * @param msg
     */
    public log(msg: any) {
        if (this.debug) {
            console.log(msg);
        }
    }

    /**
     * Prints an error to the console
     * @param msg
     */
    public error(msg: any) {
        console.error(msg);
    }

    /**
     * Prints a warning to the console
     * @param msg
     */
    public warn(msg: any) {
        console.warn(msg);
    }

    //#endregion    
}

