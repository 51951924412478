import { Injectable } from '@angular/core';
import { HttpHelper } from './http-helper.service';
import { User       } from '../models/user.model';

@Injectable()
export class UsersService {

    constructor(private readonly http: HttpHelper) {
    };

    get() {
        return this.http.get('api/users');
    }

    getById(id: number) {
        return this.http.get('api/users/' + id);
    }

    getMembersOnly() {
        return this.http.get('api/users?membersOnly=true');
    }

    save(user: User) {
        return this.http.put('api/users/' + user.hid, user);
    }

    add(user: User) {
        return this.http.post('api/users', user);
    }

    del(hid: Number) {
        return this.http.del('api/users/' + hid);
    }

    getByEmail(user: User) {
        return this.http.post('api/Users/ByEmail', user);
    }
}

