import { Component, Input, Output, AfterContentInit, EventEmitter } from '@angular/core';
import { FormGroup                          } from '@angular/forms';
import { FormField                          } from '../../models/form-field.model';
import * as _ from 'lodash';

@Component({
    selector    : 'register-form',
    templateUrl : './register-form.component.html',
    styleUrls   : ['./register-form.component.scss']
})

export class RegisterFormComponent implements AfterContentInit {
    @Input() formGroup: FormGroup;
    @Input() fields: FormField[];
    @Input() cols: number;
    @Input() requiredLegend: boolean = true;
    @Output() update: EventEmitter<any> = new EventEmitter();

    formFields: any[];

    constructor(
    ) {      
    }

    ngAfterContentInit() {
        this.checkForVisibleFields();
    }

    //#region Private Methods       
   
    private checkForVisibleFields() {
        this.formFields = _.filter(this.fields, (x) => x.visible === true );
    }

    // #endregion

    // #region Public Methods  	


    hasError(field: any){
        return !this.formGroup.get(field.formControlName).valid && 
                this.formGroup.get(field.formControlName).hasError('required') ||
                (field.pattern && this.formGroup.get(field.formControlName).hasError('pattern'))
    }
    
    /**
     * Uploads receipt
     * @param ev 
     */
    updatePaymentReceipt(field: FormField, imgURI: any) {
        this.formGroup.controls[field.formControlName].setValue(imgURI);
        field.imgURI = imgURI;
        field.processImage();
    }

    /**
     * Set the size of the receipt
     * @param size 
     */
    fileSizeUpload(field: FormField, size: number) {
        field.imgSize = size;
    }

    /**
     * Creates the Receipt
     * @param ev 
     */
    fileTypeUpload(field: FormField, ev: any) {
        field.imgType = ev;
        field.processImage();        
    }
    
    // #endregion   
   
}
