import { Component                          } from '@angular/core';
import { Router                             } from '@angular/router';
import { MatDialogRef                       } from '@angular/material';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig     } from '@angular/material';
import { HttpHelper                         } from '../../services/http-helper.service';
import { MessageBusyService                 } from '../../services/message-busy.service';
import { MessageError                       } from '../../models/message-error.model';
import { CommunicationService               } from '../../services/communication.service';

@Component({
    selector    : 'login',
    templateUrl : './login.component.html',
    styleUrls   : ['./login.component.scss']
})

export class LoginDialogComponent {
    showForm = true;
    loginForm: FormGroup;

    constructor(public dialogRef: MatDialogRef<LoginDialogComponent>,
        public fb: FormBuilder,
        public http: HttpHelper,
        public snackBar: MatSnackBar,
        private router: Router,
        private commService: CommunicationService,
        private msgBusyService: MessageBusyService
    ) {
        this.loginForm = this.fb.group({
            email: ['', [Validators.required]],
            password: ['', [Validators.required]]
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    login(event: Event) {
        const data: any = {
            email: this.loginForm.value.email,
            password: this.loginForm.value.password

        };
        this.msgBusyService.start("Contactando Servidor");
        this.http.post('api/login', data).subscribe(
            (user: any) => {
                if (user) {                    
                    this.dialogRef.close();
                    //if (user.role === 'admin') {
                    this.router.navigateByUrl('admin/dashboard');
                    this.msgBusyService.update("Cargando Informacion");
                    this.commService.set("user",user);
                    
                    //}
                } else {
                    this.msgBusyService.error(new MessageError("Error", "Favor de verificar tu contrasseña", ""));
                }
            },
            error => {
                console.log(error);
                this.msgBusyService.error(new MessageError("Error", error, ""));
            });
    }
}
