import { Component, NgZone, OnDestroy   } from '@angular/core';

@Component({
    selector    : 'message-spinner',
    templateUrl : './message-spinner.component.html',
    styleUrls   : ['./message-spinner.component.scss']
})

export class MessageSpinnerComponent {
    public expired = false;
    private timer: any;
    private timeout: number = 32000;
   
    constructor(
    ) {
       this.setTimer();
    }

    // #region Private Methods


    /**
     * Starts internal Timer
     * Emits an Error Change if times out
     */
    private setTimer() {        
        this.cancelTimer();
        this.timer = setTimeout(() => {
              this.expired = true;
        }, this.timeout);
    }

    /**
     * Cancels internal timer if exists
     */
    private cancelTimer() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    // #endregion

    // #region Public Methods

    // #endregion

}

