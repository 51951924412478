import { Component                          } from '@angular/core';
import { MatDialogRef                       } from '@angular/material';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { RegistriesService                  } from '../../../services/registries.service';
import { MessageBusyService                 } from '../../../services/message-busy.service';

@Component({
    selector    : 'add-registry-dialog',
    templateUrl : './add-registry-dialog.component.html',
    styleUrls   : ['./add-registry-dialog.component.scss']
})

export class AddRegistryDialogComponent {
    showForm = true;
    newRegisteredUserForm: FormGroup;

    constructor(
        private msgBusyService: MessageBusyService,
        public dialogRef: MatDialogRef<AddRegistryDialogComponent>,
        public fb: FormBuilder,
        public registriesService: RegistriesService
    ) {
            this.newRegisteredUserForm = this.fb.group({ email: ['', [Validators.required]] });
    }

    close(): void {
        this.dialogRef.close();
    }

    go(event: Event) {
        const data: any = {
            email: this.newRegisteredUserForm.value.email,
        };
        console.log(data);
        this.msgBusyService.start("Creando");
        this.registriesService.getByEmail(6, data).subscribe(
            response => {
                this.msgBusyService.done();
                if (response === null) {
                    this.dialogRef.close(data.email);                    
                } else {
                    // let config = new MatSnackBarConfig();
                    // config.extraClasses = ['custom-class'];
                    // config.duration = 5000
                    // this.snackBar.open("Error", "Usuario ya Existe", config);
                }

            },
            error => {
                console.log(error);
            });
    }
}
