import { Component } from '@angular/core';

@Component({
    selector: 'share',
    templateUrl: './share.component.html',
    styleUrls: ['./share.component.scss']
})

export class ShareComponent {

    share(id: string) {
        let url = 'http://www.woombmexico.com/que-es-mob/';
        switch (id) {
            case 'facebook':
                window.open('https://www.facebook.com/sharer/sharer.php?u=' + url, 'facebook-share-dialog', 'width=626,height=436');
                break;
            case 'twitter':
                window.open('https://twitter.com/share?text=Que es MOB&amp;url=' + url, 'popupwindow', 'scrollbars=yes,width=630,height=440');
                break;
            case 'googleplus':
                window.open('https://plus.google.com/share?url=' + url, 'popupwindow', 'scrollbars=yes,width=630,height=440');
                break;
            case 'linkedin':
                window.open('http://www.linkedin.com/shareArticle?mini=true&amp;url=' + url + '&amp;title=Qu� es MOB&amp;summary=&amp;source=', 'popupwindow', 'scrollbars=yes,width=630,height=440');
                break;
            case 'pinterest':
                window.open('//www.pinterest.com/pin/create/button/?url=' + url + '/&amp;media=&amp;description=Qu� es MOB', 'popupwindow', 'scrollbars=yes,width=630,height=440');
                break;
        }
    }
}
