import { Injectable } from '@angular/core';

/**
 * ----------------------------------------------------------
 *                     DefaultsService
 * ----------------------------------------------------------
 * 
 * Used to define default values that may or may not persist
 * Currently is used to setup the debug mode which is
 * used to know if we should print debug logs into the console
 * 
 * ----------------------------------------------------------
 */
@Injectable()
export class DefaultsService {

    constructor( ) { }

    public defaults: any = {        
        debugMode       : true
    };
    
}   
