import { Injectable } from '@angular/core';
import { HttpHelper } from './http-helper.service';
import { Zone   } from '../models/zone.model';

@Injectable()
export class ZonesService {

    constructor(private readonly http: HttpHelper) {
    };

    get() {
        return this.http.get('api/zones');
    }

    save(zone: Zone) {
        return this.http.put('api/zones/' + zone.hid, zone);
    }

    add(zone: Zone) {
        return this.http.post('api/zones', zone);
    }

    del(hid: Number) {
        return this.http.del('api/zones/' + hid);
    }
}

