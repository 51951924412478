import { NgModule, Optional, SkipSelf   } from '@angular/core';
import { RouterModule                   } from '@angular/router';
import { CommonModule                   } from '@angular/common';
import { LoggerService                  } from './services/logger.service';
import { DefaultsService                } from './services/defaults.service';
import { SharedModule                   } from '../shared/shared.module';
import { throwIfAlreadyLoaded           } from './module-import-guard';

@NgModule({
    imports     : [CommonModule, RouterModule, SharedModule],
    providers   : [LoggerService, DefaultsService],
    exports     : [],
    declarations: []
})

export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
