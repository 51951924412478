import { Injectable } from '@angular/core';
import { HttpHelper } from './http-helper.service';
import { SubZone    } from '../models/subZone.model';

@Injectable()
export class SubZonesService {

    constructor(private readonly http: HttpHelper) {
    };

    get() {
        return this.http.get('api/subzones');
    }

    save(subzone: SubZone) {
        return this.http.put('api/subzones/' + subzone.hid, subzone);
    }

    add(subzone: SubZone) {
        return this.http.post('api/subzones', subzone);
    }

    del(hid: Number) {
        return this.http.del('api/subzones/' + hid);
    }
}

