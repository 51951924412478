import { Injectable, Inject                 } from '@angular/core';
import { Http, Response, RequestOptionsArgs } from '@angular/http';
import { Observable, of                     } from 'rxjs';
import { map, catchError                    } from 'rxjs/operators';
import { MessageError                       } from '../models/message-error.model';
import { MessageBusyService                 } from './message-busy.service';

/**
 * Interface defines object that is returned from calls to HttpHelper.query()
 */
export interface IHttpHelperQueryResult<T> {
    data: T;
    totalCount: number;
}

@Injectable()
export class HttpHelper {
    baseUrlElem: HTMLAnchorElement;
    gatewayUrlElem: HTMLAnchorElement;

    constructor(
        private readonly http: Http,
        private msgBusyService: MessageBusyService
    ) { }

    get<T>(url: string, options?: RequestOptionsArgs, raw: boolean = false): Observable<T> {       
        return this.http.get(url, options).pipe(
            map((res: Response) => this.extractData<T>(res, raw)),
            catchError(this.handleError));
    }

    del<T>(url: string, options?: RequestOptionsArgs, raw: boolean = false): Observable<T> {
        return this.http.delete(url, options).pipe(
            map((res: Response) => this.extractData<T>(res, raw)),
            catchError(this.handleError));
    }

    post<T>(url: string, body: any, options?: RequestOptionsArgs): Observable<T> {
        return this.http.post(url, body, options).pipe(
            map((res: Response) => this.extractData<T>(res)),
            catchError(this.handleError));
    }

    put<T>(url: string, body: any, options?: RequestOptionsArgs): Observable<T> {
        return this.http.put(url, body, options).pipe(
            map((res: Response) => this.extractData<T>(res)),
            catchError(this.handleError));
    }

    private extractData<T>(res: Response, raw: boolean = false) {
        if (res.status < 200 || res.status >= 300) {
            throw new Error('Bad response status: ' + res.status);
        }
        let body = res.json ? res.json() : null;
        return body != null && body.data && !raw? body.data as T : body as T;
    }

    private handleError(error: any) {
        let errorMessage;
        let errorBody;
        if (error instanceof Response) {
            try {
                errorBody = error.json();
                
                if (errorBody.errorMessage) {
                    errorMessage = new MessageError("Http Error",errorBody.errorMessage,errorBody.errorDetail );
                } else {                    
                    errorMessage = new MessageError("Http Error",errorBody.Message,errorBody.Status );
                }
                
            } catch (e) {
                errorMessage = new MessageError("Http Error",error.statusText," Error Code: " + error.status );  
            } 
        }
        
        this.msgBusyService.error(new MessageError("Http Error",JSON.stringify(errorMessage),""));
        return of(null);
    }
}

