import { Injectable } from '@angular/core';

/**
 * Defaults Service
 */
@Injectable()
export class DefaultsService {
    /**
     * Initializes a new instance of the DefaultsService
     */
    constructor( ) { }


    public defaults: any = {
        debugMode       : true
    };
    
}
