import { Component, Input, Output, EventEmitter   } from '@angular/core';
import { MatDialog                  } from '@angular/material';
import { EditAmmountDialogComponent } from '../../dialogs/edit/edit-ammount-dialog/edit-ammount-dialog.component';

@Component({
    selector    : 'payment-due',
    templateUrl : './payment-due.component.html',
    styleUrls   : ['./payment-due.component.scss']
})

export class PaymentDueComponent {
    @Input()  data;
    @Output() paymentChanged: EventEmitter<any> = new EventEmitter();

    constructor(
        private dialog: MatDialog
     ) { 
    }

    edit(width = '100vw') {
        const dialogRef = this.dialog.open(EditAmmountDialogComponent,
        {
            width: width, disableClose: true,
            data: this.data
        });    
        dialogRef.afterClosed().subscribe(() => {this.paymentChanged.emit();});
    }
}
