import { Injectable } from '@angular/core';
import { HttpHelper } from './http-helper.service';
import { Payment    } from '../models/payment.model';


@Injectable()
export class PaymentsService {

    constructor(private readonly http: HttpHelper) {
    };

    get() {
        return this.http.get('api/payments');
    }

    getByCongress(id: number) {
        return this.http.get('api/payments/congress?congressId=' + id);
    }

    getByCongressAndFolio(id: number, folio: number) {
        return this.http.get('api/payments/congress/folio?congressId=' + id + '&folioId=' + folio);
    }

    getById(id: number) {
        return this.http.get('api/payments/' + id);
    }

    save(payment: Payment) {
        return this.http.put('api/payments/' + payment.hid, payment);
    }

    add(payment: Payment) {
        return this.http.post('api/payments', payment);
    }

    del(hid: Number) {
        return this.http.del('api/payments/' + hid);
    }

}

