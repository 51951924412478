import { Directive, ElementRef, Input, Output, HostListener, EventEmitter } from '@angular/core';
import { MessageBusyService } from '../services/message-busy.service';
import * as _ from 'lodash';
import { MessageError } from '../models/message-error.model';

@Directive({
    selector: "[fileUpload]",

})

export class FileUploadDirective {
    //@Output('fileError') fileError: any;
    @Output('fileContent') fileContent = new EventEmitter<string>();
    @Output('fileType') fileType = new EventEmitter<string>();
    @Output('fileSize') fileSize = new EventEmitter<number>();
    @Input('accept') accept: any;
    error: string;
    element: any;
    selectedFile: any;
    //@ViewChild('file2') file: any;
    public files: Set<File> = new Set();

    constructor(
        private el: ElementRef,
        private msgBusyService: MessageBusyService
    )
    {  
        this.element = el.nativeElement;
    }

    @HostListener('change')
    private onChange() {
        this.error = null;
        var reader = new FileReader();
        let that = this;
        reader.onload = function (onLoadEvent: any) {
            setTimeout(function () {
                that.selectedFile = that.element.files[0];
                that.fileContent.emit(onLoadEvent.target.result.split(",")[1]);
                that.fileType.emit(that.selectedFile.type);
                that.fileSize.emit(that.selectedFile.size / 1024);
                that.element.value ="";
            });
        };
        reader.onerror = function (e) {
            setTimeout(function () {
                that.selectedFile = "Error:" + e;
                that.fileContent = null;
            });
        };

        var fileIsValid = false;
        if (this.accept) {
            _.each(this.accept.split(","), (ext) => {
                if (this.element.files[0].name.indexOf(ext.trim()) > 0) {
                    fileIsValid = true;
                    return;
                }
            });
        } else {
            fileIsValid = true;
        };


        if (!fileIsValid) {
            setTimeout(() => {
                this.error = "El Archivo tiene que ser de tipo: " + this.accept.toString();
                this.msgBusyService.error(new MessageError("Archivo",this.error,""));
            });
        } else {
            reader.readAsDataURL(this.element.files[0]);
        }
    }

}
