import { Component, Inject                  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA      } from '@angular/material';
import { FormBuilder, FormGroup             } from '@angular/forms';
import { Registry                           } from '../../../models/registry.model';
import { Payment                            } from '../../../models/payment.model';
import { HttpHelper                         } from '../../../services/http-helper.service';
import { MessageBusyService                 } from '../../../services/message-busy.service';
import { RegistriesService                  } from '../../../services/registries.service';
import { ImagesService                      } from '../../../services/images.service';
import { PaymentsService                    } from '../../../services/payments.service';
import { FormField                          } from '../../../models/form-field.model';
import { ImageObject                        } from '../../../models/image.model';
import { mergeMap                           } from 'rxjs/operators';
import { of                                 } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import { SelectOption } from 'src/app/shared/models/select-option.model';

@Component({
    selector    : 'add-payment-dialog',
    templateUrl : './add-payment-dialog.component.html',
    styleUrls   : ['./add-payment-dialog.component.scss']
})

export class AddPaymentDialogComponent {  
    mainRegistry    : Registry      = null;
    newPayment      : Payment       = null;
    receipt         : ImageObject   = null;
    formGroup       : FormGroup     = null;
    fields          : FormField[]   = [];
    ready           : boolean       = false; 
    
     // Form
     payment_group = {
        paymentType         : 'Efectivo',
        paymentDate1        : [new Date()],
        paymentAmmount1     : [0],
        bankName            : ['Banamex'],
        paymentReference1   : [''],
        paymentReceipt1     : [''],  
        paymentReceipt1Type : [''], 
        paymentReceipt1Size : [0], 
        
    }
    listOfPaymentTypes: SelectOption[]  = [
        new SelectOption('Efectivo'),
        new SelectOption('Deposito')
    ]
    // Fields    
    paymentType       : FormField;
    submitPayment     : FormField;
    noCashPayment     : FormField;
    counterPayment    : FormField;
    transferPayment   : FormField;
    paymentDate1      : FormField;
    bankName          : FormField;
    paymentAmmount1   : FormField;
    paymentReference1 : FormField;
    partialPayment    : FormField;
    paidInFull        : FormField;
    paymentReceipt1   : FormField;

    constructor(
        public dialogRef: MatDialogRef<AddPaymentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Registry,
        public fb: FormBuilder,
        public http: HttpHelper,
        private imageService: ImagesService,
        private paymentsService: PaymentsService,
        private msgBusyService: MessageBusyService,
        private registriesService: RegistriesService
    ) {
        this.mainRegistry   = data;
        this.createForm();
    }

    //#region Private Methods
   
    onPaymentEventChange = (field: FormField, value: any) => {
        this.paymentAmmount1.visible    = true;       
        switch(value) {
            case 'Efectivo':
                this.bankName.visible           = false;
                this.paymentDate1.visible       = false;
                this.bankName.visible           = false;
                this.paymentReference1.visible  = false;
                this.paymentReceipt1.visible    = false;                
                break;
            case 'Deposito':
                this.bankName.visible           = true;
                this.paymentDate1.visible       = true;
                this.bankName.visible           = true;
                this.paymentReference1.visible  = true;
                this.paymentReceipt1.visible    = true;
                break;
        }    
    };
    
    private createForm() {        
        this.paymentType       = new FormField('form3', 'paymentType',      'select',   true, 3, 1, false, this.listOfPaymentTypes,true, this.onPaymentEventChange);        
        this.paymentDate1      = new FormField('form3', 'paymentDate1',     'date',     true, 3, 1, false, []);
        this.paymentAmmount1   = new FormField('form3', 'paymentAmmount1',  'text',     true, 3, 1, false, []);
        this.bankName          = new FormField('form3', 'bankName',         'select',   true, 3, 1, false, [new SelectOption('Banamex')], false );
        this.paymentReference1 = new FormField('form3', 'paymentReference1','text',     true, 3, 1, false, [], false);
        this.paymentReceipt1   = new FormField('form3', 'paymentReceipt1',  'image',    true, 12, 1, false, [], false);
        this.formGroup         = this.fb.group(this.payment_group);
        this.fields            = [this.paymentType, this.paymentDate1, this.paymentAmmount1, this.bankName, this.paymentReference1, this.paymentReceipt1 ];
        this.ready             = true;
    }

    private updatePaymentWithFormValues(){
        let cashPayment = this.formGroup.value.paymentType;
        this.newPayment.courseId    = 0;
        this.newPayment.orderId     = 0;        
        this.newPayment.ammount     = parseFloat(this.formGroup.value.paymentAmmount1);
        this.newPayment.congressId  = this.mainRegistry.congressId;
        this.newPayment.folio       = this.mainRegistry.folio;
        this.newPayment.createDate  = moment().format("YYYY-MM-DD");
        let receiptDate = this.formGroup.value.paymentDate1 as Date;
        this.newPayment.modifyDate  = receiptDate.toISOString().slice(0,10);
        if (cashPayment === 'Efectivo') {
            this.newPayment.bank = '';
            this.newPayment.reference = '';
        } else {
            this.newPayment.bank = this.formGroup.value.bankName;
            this.newPayment.reference = this.formGroup.value.paymentReference1;
        }
    }

    private saveReceipt() {
        let hasReceipt = this.formGroup.get('paymentReceipt1').value;
        if (hasReceipt) {
            let receipt     = new ImageObject();
            receipt.type    = this.paymentReceipt1.imgType;            
            receipt.size    = Math.round(this.paymentReceipt1.imgSize);
            receipt.content = this.formGroup.value.paymentReceipt1;
            return this.imageService.add(receipt).pipe(
                mergeMap((newImageId: number) => {
                    this.newPayment.imageId = newImageId;
                    return of(true);
                })
            );
        } else {
            return of(null)
        }
        
    }

    //#endregion

    //#region Public Methods

    close(): void {
        this.dialogRef.close(null);
    }

    save() {
        this.newPayment = new Payment();
        this.updatePaymentWithFormValues();
        this.saveReceipt().subscribe(() => {
            console.log(this.newPayment );
            this.msgBusyService.start("Guardando");
            this.paymentsService.add(this.newPayment).subscribe((newRegistry) => {
                this.msgBusyService.done();
                this.dialogRef.close(this.newPayment);
            });
        })
    }

    //#endregion
}
