import { Injectable } from '@angular/core';
import { HttpHelper } from './http-helper.service';
import { Province   } from '../models/province.model';

@Injectable()
export class ProvincesService {

    constructor(private readonly http: HttpHelper) {
    };

    get() {
        return this.http.get('api/provinces');
    }

    save(member: Province) {
        return this.http.put('api/provinces/' + member.hid, member);
    }

    add(member: Province) {
        return this.http.post('api/provinces', member);
    }

    del(hid: Number) {
        return this.http.del('api/provinces/' + hid);
    }

    getByEmail(member: Province) {
        return this.http.post('api/provinces/ByEmail', member);
    }

}

