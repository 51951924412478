import { NgModule               } from '@angular/core';
import { Routes, RouterModule   } from '@angular/router';
import { AdminGuard             } from './shared/guards/admin-guard/admin.guard';

const routes: Routes = [
    { path: '',         redirectTo: 'intro', pathMatch: 'full'                              },
    { path: '',         loadChildren: './modules/home/home.module#HomeModule'               },
    { path: 'jornada2020',    loadChildren: './modules/promo/promo.module#PromoModule'   },
    { path: 'admin',    loadChildren: './modules/admin/admin.module#AdminModule'
        , canActivate: [AdminGuard]
    },
    //{ path: '', loadChildren: './modules/privacy/privacy.module#PrivacyModule' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        onSameUrlNavigation: 'reload',
        scrollPositionRestoration: 'enabled'
    })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
export const routedComponents = [ ];
