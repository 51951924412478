import { Component, Input, Output, EventEmitter   } from '@angular/core';
import { MatDialog                  } from '@angular/material';
import { EditPaymentDialogComponent } from '../../dialogs/edit/edit-payment-dialog/edit-payment-dialog.component';
import { ConfirmDialogComponent     } from '../../dialogs/confirm/confirm.component';
import { ImagesService              } from '../../services/images.service';
import { Payment                    } from '../../models/payment.model';
import { MessageBusyService         } from '../../services/message-busy.service';
import { MessageError               } from '../../models/message-error.model';
import { PaymentsService            } from '../../services/payments.service';

@Component({
    selector    : 'payment-receipt',
    templateUrl : './payment-receipt.component.html',
    styleUrls   : ['./payment-receipt.component.scss']
})

export class PaymentReceiptComponent {
    @Input()  data: Payment;
    @Output() paymentReceiptChanged: EventEmitter<any> = new EventEmitter();
    @Output() paymentReceiptDeleted: EventEmitter<any> = new EventEmitter();

    constructor(
        private dialog: MatDialog,
        private imageService: ImagesService,
        private paymentsService: PaymentsService,
        private msgBusyService: MessageBusyService
     ) { 
    }

    convertDataURIToBinary(dataURI: string) {
        var raw = window.atob(dataURI);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));

        for (var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }


    edit(width = '100vw') {
        const dialogRef = this.dialog.open(EditPaymentDialogComponent,
        {
            width       : width, 
            disableClose: true,
            data        : this.data
        });    
        dialogRef.afterClosed().subscribe(() => {this.paymentReceiptChanged.emit();});
    }

    del(width = '100vw') {
        const dialogRef = this.dialog.open(ConfirmDialogComponent,
            {
                width       : width, 
                disableClose: true,
                data        : { title: "Borrar Acompañante", message: "Estas seguro?"}
            });    
        dialogRef.afterClosed().subscribe((ok) => {
            if (ok) {
                this.msgBusyService.start("Donativos", "Borrando");
                this.paymentsService.del(this.data.hid).subscribe(() =>{
                    this.paymentReceiptDeleted.emit();
                    this.msgBusyService.done();
                });
            }
        });
    }

    receipt() {        
        this.imageService.getImage(this.data.imageId).subscribe(
            (img: any) => {
                let byteArray = this.convertDataURIToBinary(img.content);
                let file = new Blob([byteArray], { type: img.type});
                var fileURL = window.URL.createObjectURL(file);
                window.open(fileURL);
            },
            error => { this.msgBusyService.error( new MessageError("Error","No se pudo abrir el archivo",""))}
        );

    }
}
