import { Component, NgZone, OnDestroy   } from '@angular/core';
import { MessageBusy                    } from '../../../shared/models/message-busy.model';
import { CommunicationService           } from '../../../shared/services/communication.service';
import { takeUntil                      } from 'rxjs/operators';
import { Subject                        } from 'rxjs';

@Component({
    selector    : 'message-busy',
    templateUrl : './message-busy.component.html',
    styleUrls   : ['./message-busy.component.scss']
})

export class MessageBusyComponent implements OnDestroy {
    public msgBusy: MessageBusy;
    public destroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private zone: NgZone,
        private commService: CommunicationService
    ) {        
        this.msgBusy = this.commService.get("msgBusy");
        this.commService.watch("msgBusy").pipe(
            takeUntil(this.destroyed)
        ).subscribe((val) => {
            this.updateValue("msgBusy", val);
        });
    }

    ngOnDestroy() {
        this.destroyed.next(true);
        this.destroyed.unsubscribe();
    }

    // #region Private Methods

   /**
    * Updates the value and forces a detect change
    * running the update through Angular ngZone
    */
    private updateValue(name, val) {
        if (val !== undefined && val !== null) {
            this.zone.run(() => {
                this[name] = val;
            });
        }
    }

    // #endregion

    // #region Public Methods

    // #endregion

}

