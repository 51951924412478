import { NgModule                           } from '@angular/core';
import { HttpClient, HttpClientModule       } from '@angular/common/http';
import { CommonModule                       } from '@angular/common';
import { FormsModule, ReactiveFormsModule   } from '@angular/forms';
import { FlexLayoutModule                   } from '@angular/flex-layout';
import { ModuleWithProviders                } from '@angular/compiler/src/core';
import { MaterialModule                     } from './material.module';
import { ScrollingModule                    } from '@angular/cdk/scrolling';
// Services
import { AppInjector                        } from './services/app-injector.service';
import { CommunicationService               } from './services/communication.service';
import { CongressService                    } from './services/congress.service';
import { SpinnerService                     } from './services/spinner.service';
import { LoggerService                      } from './services/logger.service';
import { DefaultsService                    } from './services/defaults.service';
import { MessageBusyService                 } from './services/message-busy.service';
import { MembersService                     } from './services/members.service';
import { UsersService                       } from './services/users.service';
import { HttpHelper                         } from './services/http-helper.service';
import { ProvincesService                   } from './services/provinces.service';
import { ImagesService                      } from './services/images.service';
import { InstructorsService                 } from './services/instructors.service';
import { SubZonesService                    } from './services/subzones.service';
import { ZonesService                       } from './services/zones.service';
import { RegistriesService                  } from './services/registries.service';
import { WorkshopsService                   } from './services/workshops.service';
import { FormsService                       } from './services/forms.service';
import { ReportsService                     } from './services/reports.service';
import { PaymentsService                    } from './services/payments.service';
// Components
import { MessageAlertComponent              } from './components/message-alert/message-alert.component';
import { MessageErrorComponent              } from './components/message-error/message-error.component';
import { MessageBusyComponent               } from './components/message-busy/message-busy.component';
import { MessageSpinnerComponent            } from './components/message-spinner/message-spinner.component';
import { HeaderComponent                    } from './components/header/header.component';
import { ShareComponent                     } from './components/share/share.component';
import { FooterComponent                    } from './components/footer/footer.component';
import { RegisterFormComponent              } from './components/register-form/register-form.component';
import { RegisterStepComponent              } from './components/register-step/register-step.component';
import { RegisterSummaryComponent           } from './components/register-summary/register-summary.component';
import { AdminHeaderComponent               } from './components/admin-header/admin-header.component';
import { CompanionComponent                 } from './components/companion/companion.component';
import { PaymentDueComponent                } from './components/payment-due/payment-due.component';
import { PaymentReceiptComponent            } from './components/payment-receipt/payment-receipt.component';
// Guard
import { AdminGuard                         } from './guards/admin-guard/admin.guard';
// Directives
import { ScrollOnTopDirective               } from './directives/scroll.directive';
import { FileUploadDirective                } from './directives/file-upload.directive';
// Pipes
import { DecodeHtmlPipe                     } from '../shared/pipes/decodeHtml.pipe';
// Dialogs
import { LoginDialogComponent               } from './dialogs/login/login.component';
import { ResetPasswordDialogComponent       } from './dialogs/reset-password/reset-password.component';
import { ConfirmDialogComponent             } from './dialogs/confirm/confirm.component';
import { PrivacyDialogComponent             } from './dialogs/privacy-dialog/privacy-dialog.component';
import { NotificationDialogComponent        } from './dialogs/notification/notification.component';
import { AddCongressDialogComponent         } from './dialogs/add/add-congress-dialog/add-congress-dialog.component';
import { AddRegistryDialogComponent         } from './dialogs/add/add-registry-dialog/add-registry-dialog.component';
import { AddWorkshopDialogComponent         } from './dialogs/add/add-workshop-dialog/add-workshop-dialog.component';
import { AddCompanionDialogComponent        } from './dialogs/add/add-companion-dialog/add-companion-dialog.component';
import { AddPaymentDialogComponent          } from './dialogs/add/add-payment-dialog/add-payment-dialog.component';
import { EditRegistryDialogComponent        } from './dialogs/edit/edit-registry-dialog/edit-registry-dialog.component';
import { EditAmmountDialogComponent         } from './dialogs/edit/edit-ammount-dialog/edit-ammount-dialog.component';
import { EditCompanionDialogComponent       } from './dialogs/edit/edit-companion-dialog/edit-companion-dialog.component';
import { EditPasswordDialogComponent        } from './dialogs/edit/edit-password-dialog/edit-password-dialog.component';
import { EditPaymentDialogComponent         } from './dialogs/edit/edit-payment-dialog/edit-payment-dialog.component';
import { EditReceiptNumberDialogComponent   } from './dialogs/edit/edit-receipt-number-dialog/edit-receipt-number-dialog.component';
// Third Party
import { AgGridModule                       } from 'ag-grid-angular';
import { AngularFontAwesomeModule           } from 'angular-font-awesome';
import { ResponsiveModule                   } from 'ngx-responsive'
import { TranslateModule, TranslateLoader   } from '@ngx-translate/core';
import { TranslateHttpLoader                } from '@ngx-translate/http-loader';
import { TranslateService                   } from '@ngx-translate/core';
//import { Angular2ImageGalleryModule } from 'angular2-image-gallery';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations    : [ MessageBusyComponent, MessageErrorComponent, MessageAlertComponent, MessageSpinnerComponent, 
                        DecodeHtmlPipe, ScrollOnTopDirective, HeaderComponent, ShareComponent, FooterComponent, 
                        FileUploadDirective, RegisterFormComponent, RegisterStepComponent, RegisterSummaryComponent,
                        LoginDialogComponent, ResetPasswordDialogComponent, ConfirmDialogComponent, PrivacyDialogComponent, 
                        NotificationDialogComponent,
                        AdminHeaderComponent, EditPasswordDialogComponent, AddCongressDialogComponent, 
                        AddRegistryDialogComponent, AddWorkshopDialogComponent, AddPaymentDialogComponent,
                        CompanionComponent, PaymentDueComponent, EditRegistryDialogComponent, EditAmmountDialogComponent,
                        EditCompanionDialogComponent, AddCompanionDialogComponent, PaymentReceiptComponent,
                        EditPaymentDialogComponent, EditReceiptNumberDialogComponent
                      ],
    imports         : [ MaterialModule, CommonModule, FormsModule, ReactiveFormsModule, FlexLayoutModule, AngularFontAwesomeModule, HttpClientModule, 
                        ScrollingModule,
                        //Angular2ImageGalleryModule, 
                        AgGridModule.withComponents([]),
                        ResponsiveModule.forRoot(),
                        TranslateModule.forRoot({
                            loader: {
                                provide: TranslateLoader,
                                useFactory: HttpLoaderFactory,
                                deps: [HttpClient]
                            }
                        }), ],
    exports         : [ MaterialModule, CommonModule, FormsModule, ReactiveFormsModule, FlexLayoutModule, ScrollOnTopDirective, AngularFontAwesomeModule, ResponsiveModule, TranslateModule, HeaderComponent, 
                        ShareComponent, FooterComponent, AgGridModule, DecodeHtmlPipe, FileUploadDirective, RegisterFormComponent, RegisterStepComponent, RegisterSummaryComponent,
                        ScrollingModule, CompanionComponent, PaymentDueComponent, PaymentReceiptComponent,
                        // Admin
                        AdminHeaderComponent
                      ],
    providers       : [ MembersService, UsersService, HttpHelper, ProvincesService, FormsService, AdminGuard,
                        ImagesService, InstructorsService, SubZonesService, ZonesService, CongressService, RegistriesService, WorkshopsService,
                        ReportsService, PaymentsService ],
    entryComponents : [ MessageBusyComponent, MessageErrorComponent, MessageAlertComponent, MessageSpinnerComponent,
                        LoginDialogComponent, ResetPasswordDialogComponent, ConfirmDialogComponent, PrivacyDialogComponent, 
                        NotificationDialogComponent,
                        EditPasswordDialogComponent, AddCongressDialogComponent, AddRegistryDialogComponent, AddWorkshopDialogComponent,
                        EditRegistryDialogComponent, EditAmmountDialogComponent, EditCompanionDialogComponent, AddCompanionDialogComponent,
                        AddPaymentDialogComponent, EditPaymentDialogComponent, EditReceiptNumberDialogComponent],
})

export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                AppInjector, CommunicationService, MessageBusyService, TranslateService, 
                DefaultsService, LoggerService, MessageBusyService, SpinnerService
            ]
        };
    }
}
