
import { Injectable                         } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegistriesService                  } from './registries.service';
import { WorkshopsService                   } from './workshops.service';
import { MessageBusyService                 } from './message-busy.service';
import { CommunicationService               } from './communication.service';
import { MessageError                       } from '../models/message-error.model';
import { Workshop                           } from '../models/workshop.model';
import { FormField                          } from '../models/form-field.model';
import { mergeMap, catchError               } from 'rxjs/operators';
import { of, forkJoin                       } from 'rxjs';
import * as _ from 'lodash';
import { Counter } from '../models/counter.model';
import { SelectOption } from '../models/select-option.model';

/**
 * ----------------------------------------------------------
 *                     FormsService
 * ----------------------------------------------------------
 *
 * Used to programatically created forms
 * 
 *  ----------------------------------------------------------
 */
@Injectable()
export class FormsService {    
    CONGRESS_ID = 6;
    registryCongressForm    : FormGroup     = null;
    step1                   : any     = null;
    step2                   : any     = null;
    step3                   : any     = null;
    step4                   : any     = null;
    counters                : Counter[]     = [];
    workshops               : Workshop[]    = []
    workshopOptions         : SelectOption[]= [];
    selectedMainEvent       : string        = null;

    registry_basic = {
        confirmed                       : [false], 
        registryEmailSent               : [false], 
        paymentReceiptReceived          : [false], 
        confirmedEmailSent              : [false], 
        congressId                      : [this.CONGRESS_ID], 
        confirmedBy                     : [0], 
        courseId                        : [0], 
        hid                             : [0], 
        houseHoldPrincipalId            : [0], 
        meetingId                       : [0],
        workshopId                      : [0], 
        createDate                      : [new Date()], 
        confirmedDate                   : [new Date(2087, 31, 12)], 
        confirmedEmailSentDate          : [new Date(2087, 31, 12)],        
        acceptsPrivacyAgreements        : [false], 
        acceptsPrivacyAgreementsVersion : ['Aug 30, 2018'],
        houseHoldOwner                  : [true], 
        statusDescription               : [''],
        relationship                    : [''], 
        folio                           : [0],
        ammountDue                      : [0],
        discount                        : [0],
        receiptNo                       : [0],
        assisted                        : [false]
    }

    // -------------------------------------------------------------------------------
    //                                      Step 1
    // -------------------------------------------------------------------------------
    
    eventTypesForPrimary    : SelectOption[]      = [
        new SelectOption('Congreso'), 
        new SelectOption('JOBI (Jovenes)'),
        new SelectOption('Tallerista, Ponente o Miembro del Equipo Nacional')
    ];

    onMainEventChange = (field: FormField, value: any) => {
        this.registry_step1_fields[8].visible = value === 'Congreso';
        this.selectedMainEvent = value;
        this.commService.set('selectedMainEvent', this.selectedMainEvent);
    };

    onEmailEventChange = (email) => {
        this.msgBusyService.start("Registro","Verificando Correo");
        this.msgBusyService.update("Verificando Correo");
        this.registriesService.verifyIfEmailAlreadyRegistered(this.CONGRESS_ID, email).subscribe((found) => {
            if (found) {
                this.step1.form.reset();
                this.msgBusyService.error(new MessageError("Registro","Ya existe un registro con ese correo","Si ya te habias registrado, no te registres de nuevo con otro correo. Contactanos para resolver cualquier duda."))
            } else {
                this.msgBusyService.done();
            }
        })
    }

    firstName = new FormField('form1', 'firstName'  );
    lastName  = new FormField('form1', 'lastName'   );
    age       = new FormField('form1', 'age', 'number');
    email     = new FormField('form1', 'email', 'email',true,3, 2, true, [], true, this.onEmailEventChange);
    phone     = new FormField('form1', 'phone'      );
    city      = new FormField('form1', 'city'       );
    state     = new FormField('form1', 'state'      );
    mainEvent = new FormField('form1', 'mainEvent', 'select',   true, 3, 1, false, this.eventTypesForPrimary,true, this.onMainEventChange);
    workshop1 = new FormField('form1', 'workshop1', 'select',   true, 3, 3, false, this.workshopOptions, false);
    notes     = new FormField('form1', 'notes',     'textarea', true, 3, 3, false, [], true);
    
    registry_step1_fields    = [
        this.firstName, 
        this.lastName, 
        this.age, 
        this.email, 
        this.phone,
        this.city, 
        this.state, 
        this.mainEvent, 
        this.workshop1, 
        this.notes
    ];

    registry_step1 = {
        email       : ['', [Validators.email, Validators.pattern("[^ @]*@[^ @]*")]], 
        name        : [''], 
        firstName   : [''], 
        lastName    : [''], 
        age         : [''], 
        phone       : [''], 
        state       : [''], 
        city        : [''], 
        hid         : [0], 
        workshops   : [''], 
        notes       : [''],
        workshop1   : [''], 
        workshop2   : [''], 
        workshop3   : [''], 
        workshop4   : [''], 
        workshop5   : [''], 
        mainEvent   : ['']
    }

    // -------------------------------------------------------------------------------
    //                                      Step 2
    // -------------------------------------------------------------------------------
    eventTypes: SelectOption[] = [
        new SelectOption('Congreso'), 
        new SelectOption('JOBI (Jovenes)'), 
        new SelectOption('Ninos (4 - 13)'), 
        new SelectOption('Guarderia (2-3)'), 
        new SelectOption('Ninguno')
    ];

    companion_step = {
        firstName       : [''], 
        lastName        : [''], 
        age             : [''], 
        relationship    : [''],
        mainEvent       : [''],
        workshop1       : ['']
    }
    relationships: SelectOption[] = [
        new SelectOption('Esposo(a)'), 
        new SelectOption('Hijo(a)'), 
        new SelectOption('Abuelo(a)'), 
        new SelectOption('otro')
    ];
    relationship            = new FormField('form2', 'relationship', 'select', true, 3, 1, false, this.relationships, true);
    companion_fields        = [this.firstName, this.lastName, this.age, this.relationship]

    onCompanionMainEventChange = (field: FormField, value: any) => {
        field.targetField.visible = value === 'Congreso';        
    };

    /** Companion Sub Forms are created on demand */
    onCompanionsChange = (field: FormField, val: any) => { 
        this.step2.companionsArray = [];
        _.each(_.range(val), (i) => {
            let companion_fields = _.clone(this.companion_fields);
            let companionFormName = 'companion' + i;
            let mainEvent = new FormField('form1', 'mainEvent', 'select',   true, 3, 1, false, this.eventTypes,true, this.onCompanionMainEventChange);
            let workshop1 = new FormField('form1', 'workshop1', 'select',   true, 3, 3, false, this.workshopOptions, false);
            mainEvent.targetField = workshop1;
            companion_fields.push(mainEvent);
            companion_fields.push(workshop1);
            let form = { formName: companionFormName, form: this.getNewRegistryForm(), fields: companion_fields };
            this.step2.companionsArray.push(form);
        });
        this.commService.set('companionsArray', this.step2.companionsArray)
    }

    listOfCompanions: SelectOption[]  = [
        new SelectOption('0'), new SelectOption('1'), new SelectOption('2'), 
        new SelectOption('3'), new SelectOption('4'), new SelectOption('5'), 
        new SelectOption('6'), new SelectOption('7'), new SelectOption('8')
    ];
    companions                  = new FormField('form2', 'companions', 'select',   true, 3, 1, false, this.listOfCompanions,true, this.onCompanionsChange);
    requiresHomeAccomodation    = new FormField('form2', 'requiresHomeAccomodation', 'checkbox', false,3, 2, false, [], false);
    registry_step2_fields       = [this.companions, this.requiresHomeAccomodation];
    registry_step2              = { 
        companions                  : [''], 
        requiresHomeAccomodation    : [false]}
    paymentTypeSelected         = '';

    // -------------------------------------------------------------------------------
    //                                      Step 3
    // -------------------------------------------------------------------------------
    listOfPaymentTypes: SelectOption[]  = [
        new SelectOption('Pago en Efectivo el Dia del Evento'),
        new SelectOption('Deposito Bancario en Ventanilla'),
        new SelectOption('Transferencia Bancaria')
    ]

    onPaymentEventChange = (field: FormField, value: any) => {
        this.paymentTypeSelected        = value;
        this.paymentDate1.visible       = false;
        this.bankName.visible           = false;
        this.paymentDate1.visible       = false;
        this.bankName.visible           = false;
        this.paymentAmmount1.visible    = false;
        this.paymentReference1.visible  = false;
        this.partialPayment.visible     = false;
        this.paidInFull.visible         = false;
        this.paymentReceipt1.visible    = false;

        switch(value) {
            case 'Pago en Efectivo el Dia del Evento':
                this.submitPayment.visible = false;
                this.noCashPayment.visible = true;
                this.goToSummary.visible = true;
                break;
            case 'Deposito Bancario en Ventanilla':
            case 'Transferencia Bancaria':
                this.submitPayment.visible = true;
                this.noCashPayment.visible = false;
                this.goToSummary.visible = false;
                break;
        }    
    };

    onSubmitPaymentEventChange = (field: FormField, value: any) => {
        switch(value) {
            case 'Si':
                this.counterPayment.visible     = false;
                this.transferPayment.visible    = false;
                this.goToSummary.visible        = false;
                this.paymentDate1.visible       = true;
                this.bankName.visible           = true;
                this.paymentAmmount1.visible    = true;
                this.paymentReference1.visible  = true;
                this.partialPayment.visible     = true;
                this.paidInFull.visible         = true;
                this.paymentReceipt1.visible    = true;
                break;
            case 'No':
                this.paymentDate1.visible       = false;
                this.bankName.visible           = false;
                this.paymentAmmount1.visible    = false;
                this.paymentReference1.visible  = false;
                this.partialPayment.visible     = false;
                this.paidInFull.visible         = false;
                this.paymentReceipt1.visible    = false;
                this.goToSummary.visible        = true;

                if (this.paymentTypeSelected === 'Deposito Bancario en Ventanilla') {
                    this.counterPayment.visible     = true;
                    this.transferPayment.visible    = false;
                } else {
                    this.counterPayment.visible     = false;
                    this.transferPayment.visible    = true;
                }
                break;
        }    
    };

    bankTypes                   : string[]  = ['Banamex'];
    
    paymentType       = new FormField('form3', 'paymentType',      'select',   true, 3, 1, false, this.listOfPaymentTypes,true, this.onPaymentEventChange);
    submitPayment     = new FormField('form3', 'submitPayment',    'select',   true, 3, 1, false, [new SelectOption('Si'),new SelectOption('No')], false, this.onSubmitPaymentEventChange);
    noCashPayment     = new FormField('form3', 'noCashPayment',    'label',    true, 6, 3, false, [], false);
    counterPayment    = new FormField('form3', 'counterPayment',   'label',    true, 6, 3, false, [], false);
    transferPayment   = new FormField('form3', 'transferPayment',  'label',    true, 6, 3, false, [], false);
    goToSummary       = new FormField('form3', 'goToSummary',      'label',    true, 3, 3, false, [], false);
    paymentDate1      = new FormField('form3', 'paymentDate1',     'date',     true, 3, 1, false, [], false);
    bankName          = new FormField('form3', 'bankName',         'select',   true, 3, 1, false, [new SelectOption('Banamex')], false );
    paymentAmmount1   = new FormField('form3', 'paymentAmmount1',  'number',   true, 3, 1, false, [], false);
    paymentReference1 = new FormField('form3', 'paymentReference1','text',     true, 3, 1, false, [], false);
    partialPayment    = new FormField('form3', 'partialPayment',   'checkbox', true, 3, 1, false, [], false);
    paidInFull        = new FormField('form3', 'paidInFull',       'checkbox', true, 3, 1, false, [], false);
    paymentReceipt1   = new FormField('form3', 'paymentReceipt1',  'image',    true, 12, 1, false, [], false);

    registry_step3_fields    = [ 
        this.paymentType, 
        this.submitPayment, 
        this.paymentReceipt1,
        this.noCashPayment, 
        this.counterPayment, 
        this.transferPayment, 
        this.partialPayment,
        this.paidInFull,
        this.paymentDate1, 
        this.bankName, 
        this.paymentAmmount1,
        this.paymentReference1,
        this.goToSummary
    ];


    registry_step3 = {
        partialPayment          : [false], 
        paymentReceipt1         : [''], 
        paymentReceipt2         : [''], 
        paymentReceipt1Type     : [''], 
        paymentReceipt2Type     : [''], 
        paymentAmmount1         : [0], 
        paymentAmmount2         : [0], 
        bankName                : ['Banamex'], 
        paymentType             : [''], 
        submitPayment           : ['No'], 
        paidInFull              : [false],
        paymentDate1            : [new Date()], 
        paymentDate2            : [new Date(2087, 31, 12)], 
        paymentReference1       : ['Ninguna'], 
        paymentReference2       : [''], 
        paymentReceipt1ImageId  : [0], 
        paymentReceipt2ImageId  : [0], 
        paymentReceipt1Size     : [0], 
        paymentReceipt2Size     : [0]
    }

    
    // -------------------------------------------------------------------------------
    //                                      Step 4 - Summary
    // -------------------------------------------------------------------------------
    summaryPersonalDataFields = [
        this.firstName, 
        this.lastName, 
        this.email, 
        this.age, 
        this.phone, 
        this.city, 
        this.state,
        this.notes, 
        this.workshop1, 
        this.requiresHomeAccomodation, 
        this.companions
    ];

    summaryPaymentFields = [
        this.paymentType,
        this.submitPayment,
        this.bankName,
        this.paymentDate1,
        this.paymentAmmount1,
        this.paymentReference1,
        this.partialPayment,
        this.paidInFull
        //this.paymentReceipt1
    ]

    summaryCompanionFields = [
        this.firstName,
        this.lastName,
        this.age,
        this.mainEvent,
        this.workshop1
    ]

    constructor(
        public fb: FormBuilder,
        private commService: CommunicationService,
        private workshopsService: WorkshopsService,
        private msgBusyService: MessageBusyService,
        private registriesService: RegistriesService,
    ) { 
        this.loadWorkshops();
    }    

    // #region Private Methods

    /**
     * Retrieves the list of workshops from server
     */
    private loadWorkshops() {
        this.msgBusyService.start("Cargando Talleres");
        this.workshopsService.getCounters(this.CONGRESS_ID).pipe(
            mergeMap((counters: Counter[]) => { 
                this.counters = counters;       
                return this.workshopsService.getByCongress(this.CONGRESS_ID);
            }),
            catchError ((e) => { 
                this.msgBusyService.error(new MessageError("Congreso","Talleres",JSON.stringify(e))); 
                return of(null);
            })).subscribe((data: Workshop[]) => {                 
                this.workshops = [];
                this.workshop1.options = [];
                _.each(data, (w) => {
                    let found = _.find(this.counters, (c: Counter) => c.hid === w.hid )
                    if (found) {
                        this.workshops.push(w);
                        //let desc = w.description + " - Capacidad: " + found.capacity + " Espacios Disponibles: " + found.openSeats;
                        let desc = "Espacios Disponibles: " + found.openSeats;
                        let option = {
                            title   : w.title,
                            desc    : desc,
                            disabled: found.isFull
                        }
                        this.workshopOptions.push(option);
                        this.workshop1.options.push(option);
                    }
                })                
                this.commService.set("workshops", this.workshops);
                this.msgBusyService.done();
            });
    }  

    private setCopyFormValue(destinationForm: FormGroup, fields: any) {
        let srcForm: any;

        _.each(fields, (field: FormField) => {
            srcForm = null;
            switch(field.form) {
                case 'form1':
                    srcForm = this.step1.form;
                    break;                    
                case 'form2':
                    srcForm = this.step2.form;
                    break;                        
                case 'form3':
                    srcForm = this.step3.form;
                    break;
            }
            if (srcForm) {
                destinationForm.controls[field.formControlName].setValue(srcForm.value[field.formControlName]);
            }
            
        })
    }

    // #endregion

    // #region Public Methods    

    getNewRegistryForm() {
        let registryForm = { ...this.registry_basic, ...this.registry_step1, ...this.registry_step2, ...this.registry_step3};
        return this.fb.group(registryForm);
    }

    getNewRegistryFormStep1() {
        this.step1 = { formName:'formGroup1', form: this.fb.group(this.registry_step1), fields: this.registry_step1_fields };
        return this.step1;
    }

    getNewRegistryFormStep2() {
        this.step2 = { formName:'formGroup2', form: this.fb.group(this.registry_step2), fields: this.registry_step2_fields, companionsArray: [] }
        return this.step2;
    }

    getNewRegistryFormStep3() {
        this.step3 = { formName:'formGroup3', form: this.fb.group(this.registry_step3), fields: this.registry_step3_fields }
        return this.step3;
    }

    getSummaryStep4() {
        let registryForm = { ...this.registry_basic, ...this.registry_step1, ...this.registry_step2, ...this.registry_step3};
        let form = this.fb.group(registryForm);
        this.setCopyFormValue(form, this.summaryPersonalDataFields);
        this.setCopyFormValue(form, this.summaryPaymentFields);
        this.setCopyFormValue(form, ['paymentReceipt1']);
        // Set Img Size and Img Type
        form.controls['paymentReceipt1'].setValue(this.step3.form.value['paymentReceipt1']);
        
        form.controls['paymentReceipt1Size'].setValue(Math.round(this.paymentReceipt1.imgSize).toString());
        form.controls['paymentReceipt1Type'].setValue(this.paymentReceipt1.imgType);
        let groups = [
             { name: 'Datos Personales', fields: this.summaryPersonalDataFields },
             { name: 'Pago',             fields: this.summaryPaymentFields }
        ]


        this.step4 = { formName:'registryCongressForm', form: form, groups: groups, summaryCompanionFields: this.summaryCompanionFields }
        return this.step4;
    }

    // #endregion
}
