export class Payment {
    hid: number;
    createDate: string | null;
    modifyDate: string | null; 
    congressId: number;
    courseId: number;
    orderId: number;
    ammount: number;
    imageId: number;
    folio: number;
    bank: string;
    reference: string;
}
