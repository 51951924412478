import { Injectable } from '@angular/core';
import { HttpHelper } from './http-helper.service';
import { Member     } from '../models/member.model';

@Injectable()
export class MembersService {

    constructor(private readonly http: HttpHelper) {
    };

    get() {
        return this.http.get('api/members');
    }

    save(member: Member) {
        return this.http.put('api/members/' + member.hid, member);
    }

    add(member: Member) {
        return this.http.post('api/members', member);
    }

    del(hid: Number) {
        return this.http.del('api/members/' + hid);
    }

    getByEmail(member: Member) {
        return this.http.post('api/members/ByEmail', member);
    }

}

