export class MessageError {
    constructor(public title:string, public details: string, public hint: string) {
    }

    public clean() {
        this.title = "";
        this.details = "";
        this.hint = "";
    }

    public set(title: string, details: string, hint: string) {
        this.title = title;
        this.details = details;
        this.hint = hint;
    }

    public isActive() {
        return this.title !== "";
    }
}
