import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog                              } from '@angular/material';
import { EditCompanionDialogComponent           } from '../../dialogs/edit/edit-companion-dialog/edit-companion-dialog.component';
import { ConfirmDialogComponent                 } from '../../dialogs/confirm/confirm.component';

@Component({
    selector    : 'companion',
    templateUrl : './companion.component.html',
    styleUrls   : ['./companion.component.scss']
})

export class CompanionComponent {
    @Input()  title;
    @Input()  data;
    @Input()  editable = false;
    @Input()  workshop = false;
    @Output() companionChanged: EventEmitter<any> = new EventEmitter();
    @Output() companionDeleted: EventEmitter<any> = new EventEmitter();

    constructor(
        private dialog: MatDialog
     ) { 
    }

    edit(width = '100vw') {
        const dialogRef = this.dialog.open(EditCompanionDialogComponent,
        {
            width: width, disableClose: true,
            data: this.data
        });    
        dialogRef.afterClosed().subscribe(() => {this.companionChanged.emit();});
    }

    deleteCompanion(width =  '100vw') {
        const dialogRef = this.dialog.open(ConfirmDialogComponent,
            {
                width: width, disableClose: true,
                data: { title: "Borrar Acompañante", message: "Estas seguro?"}
            });    
            dialogRef.afterClosed().subscribe((ok) => {
                if (ok) {
                    this.companionDeleted.emit(this.data);
                }
            });

    }
}
