import { Injectable                             } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot    } from '@angular/router';
import { Router, RouterStateSnapshot            } from '@angular/router';
import { Observable                             } from 'rxjs';
import { CommunicationService                   } from '../../services/communication.service';
import { User                                   } from '../../models/user.model';

@Injectable()
export class AdminGuard implements CanActivate {

    constructor(private commService: CommunicationService, private router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        
        let user : User = this.commService.get("user");
        let isActive = !!user && user.active
        if (!isActive) this.router.navigateByUrl('welcome');
        return isActive;
    }
}