import { Component, Inject                  } from '@angular/core';
import { Router                             } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA      } from '@angular/material';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig     } from '@angular/material';
import { Registry                           } from '../../../models/registry.model';
import { HttpHelper                         } from '../../../services/http-helper.service';
import { MessageBusyService                 } from '../../../services/message-busy.service';
import { MessageError                       } from '../../../models/message-error.model';
import { Workshop                           } from '../../../models/workshop.model';
import { CommunicationService               } from '../../../services/communication.service';
import { RegistriesService                  } from '../../../services/registries.service';
import { FormsService                       } from '../../../services/forms.service';
import { FormField                          } from '../../../models/form-field.model';
import * as _ from 'lodash';
import { WorkshopsService } from '../../../services/workshops.service';
import { SelectOption } from 'src/app/shared/models/select-option.model';

@Component({
    selector    : 'edit-companion-dialog',
    templateUrl : './edit-companion-dialog.component.html',
    styleUrls   : ['./edit-companion-dialog.component.scss']
})

export class EditCompanionDialogComponent {    
    registry    : Registry      = null;
    formGroup   : FormGroup     = null;
    fields      : FormField[]   = [];
    workshops   : Workshop[]    = [];
    ready       : boolean       = false; 
    // Form
    companion_group = {
        firstName       : [''], 
        lastName        : [''], 
        age             : [''], 
        relationship    : [''],
        mainEvent       : [''],
        workshop1       : ['']
    }
    eventTypes      : SelectOption[]    = [
        new SelectOption('Congreso'), 
        new SelectOption('JOBI (Jovenes)'), 
        new SelectOption('Ninos (6 - adelante)'), 
        new SelectOption('Guarderia (2-5)'), 
        new SelectOption('Ninguno')
    ];
    relationships   : SelectOption[] = [
        new SelectOption('Esposo(a)'), 
        new SelectOption('Hijo(a)'), 
        new SelectOption('Abuelo(a)'), 
        new SelectOption('otro')
    ];
    // Fields    
    firstName       : FormField;
    lastName        : FormField;
    age             : FormField;
    relationship    : FormField;
    workshop1       : FormField;
    mainEvent       : FormField;
    
  

    constructor(
        public dialogRef: MatDialogRef<EditCompanionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Registry,
        public fb: FormBuilder,
        public http: HttpHelper,
        public snackBar: MatSnackBar,
        private formsService: FormsService,
        private workshopService: WorkshopsService,
        private commService: CommunicationService,
        private msgBusyService: MessageBusyService,
        private registriesService: RegistriesService,
    ) {
        this.registry   = data;         
        this.load();
    }

    //#region Private Methods

    private load() {
        this.workshopService.getByCongress(this.registry.congressId).subscribe((workshops: any[]) => {    
            this.workshops = workshops;        
            this.commService.set("workshops", this.workshops);
            this.createForm();
            this.setFormValues();
            this.ready = true;
        })
    }

    private createForm() {
        let workshopOptions = [];     
        _.each(this.workshops, (w) => {
            workshopOptions.push(new SelectOption(w.title));
        }) 
        this.firstName      = new FormField('form1', 'firstName'  );
        this.lastName       = new FormField('form1', 'lastName'   );
        this.age            = new FormField('form1', 'age'        );
        this.relationship   = new FormField('form1', 'relationship', 'select', true, 3, 1, false, this.relationships, true);
        this.mainEvent      = new FormField('form1', 'mainEvent', 'select',   true, 3, 1, false, this.eventTypes,true, this.onCompanionMainEventChange);
        this.workshop1      = new FormField('form1', 'workshop1', 'select',   true, 3, 3, false, workshopOptions, false);       
        this.mainEvent.targetField = this.workshop1;
        this.fields         = [this.firstName, this.lastName, this.age, this.relationship, this.mainEvent, this.workshop1];
        this.formGroup = this.fb.group(this.companion_group);
        this.setWorkshopFieldVisible();
    }

    private setWorkshopFieldVisible() {
        if (this.registry.workshops != "") {
            var found = _.find(this.fields, (x) => x.formControlName === 'workshop1');
            if (found){
                found.visible = true;
            }
        }
    }

    private setWorkshopId() {
        var found = _.find(this.workshops, (x) => x.title === this.formGroup.controls['workshop1'].value);
        if (found) {
            this.registry.workshops = found.hid.toString();
        }
    }

    private setFormValues(){
        _.each(this.fields, (field: FormField) => {
            this.formGroup.controls[field.formControlName].setValue(this.registry[field.formControlName]);            
        })        
        var found = _.find(this.workshops, (x) => x.hid === parseInt(this.registry.workshops));
        if (found) {
            this.formGroup.controls['workshop1'].setValue(found.title);
        }
    }

    private updateRegistryWithFormValues(){
        _.each(this.fields, (field: FormField) => {
            this.registry[field.formControlName] = this.formGroup.get(field.formControlName).value;
        })
    }

    private onCompanionMainEventChange = (field: FormField, value: any) => {
        if (value === 'Congreso') {
            field.targetField.visible = true;
        } else {
            this.registry .workshops = '';
            field.targetField.visible = false;  
            this.formGroup.controls['workshop1'].setValue('');  
        }
    };

    //#endregion

    //#region Public Methods

    close(): void {
        this.dialogRef.close();
    }

    save() {
        this.setWorkshopId();
        this.updateRegistryWithFormValues();
        console.log(this.registry);
        this.msgBusyService.start("Guardando");
        this.registriesService.save(this.registry).subscribe(() => {
            this.msgBusyService.done();
            this.dialogRef.close();
            this.commService.set("selectedRegistry", this.registry);
        });
    }

    //#endregion
}
