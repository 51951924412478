import { Component                          } from '@angular/core';
import { MatDialogRef                       } from '@angular/material';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CongressService                    } from '../../../services/congress.service';
import { MessageBusyService                 } from '../../../services/message-busy.service';

@Component({
    selector    : 'add-congress-dialog',
    templateUrl : './add-congress-dialog.component.html',
    styleUrls   : ['./add-congress-dialog.component.scss']
})

export class AddCongressDialogComponent {
    showForm = true;
    newCongressForm: FormGroup = this.fb.group({ title: ['', [Validators.required]] });

    constructor(
        public fb: FormBuilder,
        private congressService: CongressService,
        private msgBusyService: MessageBusyService,
        private dialogRef: MatDialogRef<AddCongressDialogComponent>,
    ){
        
    }

    close(): void {
        this.dialogRef.close();
    }

    go(event: Event) {
        const data: any = {
            title: this.newCongressForm.value.title,
        };
        console.log(data);
        this.msgBusyService.start("Creando");
        this.congressService.getByTitle(data).subscribe(
            response => {
                this.msgBusyService.done();
                if (response === null) {
                    this.dialogRef.close(data.title);
                } else {
                    // let config = new MatSnackBarConfig();
                    // config.extraClasses = ['custom-class'];
                    // config.duration = 5000
                    // this.snackBar.open("Error", "El Congreso ya Existe", config);
                }

            },
            error => {
                console.log(error);
            });
    }
}
