import { Component, Inject                  } from '@angular/core';
import { Router                             } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA      } from '@angular/material';
import { Registry                           } from '../../../models/registry.model';
import { HttpHelper                         } from '../../../services/http-helper.service';
import { MessageBusyService                 } from '../../../services/message-busy.service';
import { CommunicationService               } from '../../../services/communication.service';
import { RegistriesService                  } from '../../../services/registries.service';
import * as _ from 'lodash';

@Component({
    selector    : 'edit-ammount-dialog',
    templateUrl : './edit-ammount-dialog.component.html',
    styleUrls   : ['./edit-ammount-dialog.component.scss']
})

export class EditAmmountDialogComponent {    
    registry    : Registry      = null;
    ammountDue  : number        = 0;

    constructor(
        public dialogRef: MatDialogRef<EditAmmountDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Registry,
        public http: HttpHelper,
        private commService: CommunicationService,
        private msgBusyService: MessageBusyService,
        private registriesService: RegistriesService,
    ) {
        this.registry   = data;
        this.ammountDue = _.clone(this.registry.ammountDue);
    }

    //#region Private Methods

    //#endregion

    //#region Public Methods

    close(): void {
        this.dialogRef.close();
    }

    save() {
        this.registry.ammountDue = this.ammountDue;
        this.msgBusyService.start("Guardando");
        this.registriesService.save(this.registry).subscribe(() => {
            this.msgBusyService.done();
            this.dialogRef.close();
        });
    }

    //#endregion
}
