export class MessageBusy {
    constructor(public title:string = "", public details: string = "") {
    }

    public clean() {
        this.title = "";
        this.details = "";        
    }

    public set(title: string, details: string) {
        this.title = title;
        this.details = details;
    }

    public update(details: string = '') {
        this.details = details;
    }
}
