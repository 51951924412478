
import { Injectable                     } from '@angular/core';
import { LoggerService                  } from './logger.service';
import { MessageError                   } from '../models/message-error.model';
import { Observable, BehaviorSubject    } from 'rxjs';

/**
 * ----------------------------------------------------------
 *                     CommunicationService
 * ----------------------------------------------------------
 *
 * Used to share variables across modules and web components
 * Developers needs to add the varialble first, before trying
 * to use it
 * When a variable is set, it also creates an observable 
 * subject so other can subscribe to an event when the value
 * changes
 *  
 * This service provides the following public methods:
 *
 *    - get
 *    - set
 *    - watch
 * 
 *  ----------------------------------------------------------
 */
@Injectable()
export class CommunicationService {
    public optionSubjects: Map<string, BehaviorSubject<any>> = new Map<string, BehaviorSubject<any>>();

    constructor(
        private readonly loggerService: LoggerService
    ) { }    

    // #region Private Methods

    /**
     * List of all the variables that will be shared accross the entire app
     * A value needs to be defined here prior to try to use it
     */
    private config: any = {          
        language                : "es",     // Default Language
        title                   : null,     // Used to set the title on each Page
        currentDevice           : null,     // Directive to detect size
        scrollOnTop             : null,     // Directive to Detect if scroll is on top
        scrollYPos              : 0,        // To Remember Scroll Poisition
        currentPage             : 0,        // To know when user scroll to a different page
        // Registry
        registryCongressForm    : null,     // Registry Form in Memory
        formGroup1              : null,     // Registry Form1 in Memory
        formGroup2              : null,     // Registry Form2 in Memory
        formGroup3              : null,     // Registry Form3 in Memory
        selectedMainEvent       : null,     // To know if this is Congress or JOBI
        companionsArray         : [],
        counters                : [],
        stepperIndex            : 0,
        // Admin
        user                    : null,
        congresses              : [],
        registries              : [],
        workshops               : [],
        selectedCongress        : null,
        selectedRegistry        : null,
        selectedWorkshop        : null,
        // Msg Busy, Error and Alerts
        msgBusy                 : null,
        msgError                : null,
        msgAlert                : null,
        overlayRef              : null,
    };

    /**
     * Internal Validation to check if key is defined
     * This forces developer to add the key into the list
     * and provided comments on how is being used
     * @param key 
     */
    private checkIfDefined(key: string) {
        if (key in this.config === false) {
            this.loggerService.error("Trying to set a value for that hasn't been defined: " + key);
            this.loggerService.error("Please add it to the CommunicationService first");
            throw new MessageError("Comm Service", "Trying to set a value for: " + key + " but it hasn't been defined", "Please add it to the CommunicationService first");
        }
    }
    
    /**
     * Add a subject if does not exists
     * @param key 
     */
    private addSubjectIfNeeded(key: string) {
        if (!this.optionSubjects.has(key)) {            
            this.optionSubjects.set(key, new BehaviorSubject<any>(undefined));
        }
    }

    // #endregion

    // #region Public Methods    

    /**
     * Sets the value, 
     * The key needs to be defined prior to try to set it
     * @param key 
     * @param value 
     */
    public set(key: string, value: any) {
        this.checkIfDefined(key);
        this.config[key] = value;   
        this.addSubjectIfNeeded(key);
        this.optionSubjects.get(key).next(value); // Emits new value
    }

    /**
     * Retrieves a value
     * The key needs to be defined prior to try to get it
     * @param key 
     */
    public get(key: string) {
        this.checkIfDefined(key);
        return this.config[key];
    }

    /**
     * Returns and observable subject
     * The key needs to be defined prior to try to watch it
     * @param key 
     */
    public watch(key: string): Observable<any> {
        this.addSubjectIfNeeded(key);
        return this.optionSubjects.get(key).asObservable();
    }   

    // #endregion
}
