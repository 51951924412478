export class Registry {
    hid: number;
    name: string | null;
    firstName: string | null;
    lastName: string | null;
    age: string | null;
    email: string | null;
    phone: string | null;
    state: string | null;
    city: string | null;
    workshops: string | null;
    notes: string | null;
    houseHoldOwner: boolean;
    requiresHomeAccomodation: boolean;
    companions: number;
    houseHoldPrincipalId: number;
    statusDescription: string | null;
    confirmedBy: number;
    congressId: number;
    meetingId: number;
    courseId: number;
    workshopId: number;
    confirmed: boolean;
    registryEmailSent: boolean;
    paymentReceiptReceived: boolean;
    paidInFull: boolean;
    partialPayment: boolean;
    confirmedEmailSent: boolean;
    confirmedDate: string | null;
    confirmedEmailSentDate: string | null;
    paymentReceipt1: number[];
    trustedPaymentReceipt1: any;
    paymentReceipt2: number[];
    trustedPaymentReceipt2: any;
    paymentAmmount1: number;
    paymentAmmount2: number;
    bankName: string | null;
    paymentType: string | null;
    mainEvent: string | null;
    relationship: string | null;
    paymentDate1: string | null;
    paymentDate2: string | null;
    paymentReference1: string | null;
    paymentReference2: string | null;
    paymentReceipt1ImageId: number;
    paymentReceipt2ImageId: number;
    paymentReceipt1Type: string | null;
    paymentReceipt2Type: string | null;
    acceptsPrivacyAgreements: boolean;
    acceptsPrivacyAgreementsVersion: string | null;
    folio: number;
    ammountDue: number;
    disccount: number;
    workshopsInteger: number; // Internal use aonly
    receiptNo: number;
    assisted: boolean;
}
