import { Injectable, ViewContainerRef, ElementRef                       } from '@angular/core';
import { ComponentPortal                                                } from '@angular/cdk/portal';
import { Overlay, OverlayConfig, ConnectionPositionPair, OverlayRef     } from '@angular/cdk/overlay';
import { MessageSpinnerComponent                                        } from '../components/message-spinner/message-spinner.component';
import * as _ from 'lodash';

/**
 * ----------------------------------------------------------
 *                     Spinner Service
 * ----------------------------------------------------------
 *
 * Used to turn on/off/update the spinner on a web component
 *
 * This service provides the functionality for:
 *
 *    - add
 *    - remove
 *
 * ----------------------------------------------------------
 */
@Injectable()
export class SpinnerService {  
    private overlayReferences: any[] = [];
    private positions = [
        new ConnectionPositionPair(
            {originX: 'center', originY: 'center'},
            {overlayX: 'center', overlayY: 'center'} 
        )
    ];

    constructor(
        private overlay: Overlay
    ) {
    }

    // #region Private Methods

    /**
     * Adds and Overlay
     * We keep track of all overlays, if not found we add one
     * If found we update it
     * @param elementRef 
     */
    private addOverlayRef(elementRef: ElementRef) {
        let strategy = this.overlay.position().flexibleConnectedTo(elementRef).withPositions(this.positions);
        let config = new OverlayConfig({positionStrategy: strategy });
        let overlayRef = this.overlay.create(config);
        var found = _.find(this.overlayReferences, (x) =>  x.elementRef == elementRef );
        if (found) {
            found.overlayRef.detach();
            found.overlayRef.dispose();
            found.overlayRef = overlayRef
        } else {
            this.overlayReferences.push({ elementRef: elementRef,  overlayRef: overlayRef})
        }
        return overlayRef;
    }

    /**
     * Detaches and Disposes the overlayRef if found
     * @param elementRef 
     */
    private removeOverlayRef(elementRef: ElementRef) {
        var found = _.find(this.overlayReferences, (x) =>  x.elementRef == elementRef );
        if (found) {
            found.overlayRef.detach();
            found.overlayRef.dispose();
        }
    }

    // #endregion

    // #region Public Methods

    /**
     * Adds a Spinner in the center of the web component who called it
     * @param elementRef 
     * @param viewContainerRef 
     */
    public add(elementRef: ElementRef,  viewContainerRef: ViewContainerRef) {
        if (!elementRef) return;
        // Timeout is needed since the Overlay usually is called first
        // before even the nativeElement has been rendered
        // So we need to wait for next render cycle    
        setTimeout(() => {                  
            elementRef.nativeElement.style.opacity = '0.5';
            let overlayRef = this.addOverlayRef(elementRef)
            overlayRef.attach(new ComponentPortal(MessageSpinnerComponent, viewContainerRef));
        }, 0);
    }

    /**
     * Removes the Spinner
     * @param elementRef 
     */
    public remove(elementRef: ElementRef) {  
        if (!elementRef) return;
        elementRef.nativeElement.style.opacity = '1';
        this.removeOverlayRef(elementRef);
    }

    /**
     * Remoes all spinners at once
     */
    public removeAll() {
        _.each(this.overlayReferences, (item: any) => {
            item.overlayRef.detach();
            item.overlayRef.dispose();
        })
    }

    // #endregion
}
