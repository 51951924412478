import { NgModule, Injector         } from '@angular/core';
import { CommonModule               } from '@angular/common';
import { BrowserModule              } from '@angular/platform-browser';
import { BrowserAnimationsModule    } from '@angular/platform-browser/animations';
import { HttpModule                 } from '@angular/http';
import { HttpClientModule           } from '@angular/common/http';
import { LayoutModule               } from '@angular/cdk/layout';
import { SharedModule               } from './shared/shared.module';
import { CoreModule                 } from "./core/core.module";
import { AppInjector                } from "./shared/services/app-injector.service";
import { AppRoutingModule, routedComponents } from './app-routing.module';

import { AppComponent } from './app.component';

@NgModule({
    declarations    : [ AppComponent, routedComponents ],
    imports         : [ 
        BrowserModule,
        BrowserAnimationsModule,
        HttpModule,
        HttpClientModule,
        LayoutModule,
        CommonModule,
        SharedModule,
        SharedModule.forRoot(),
        CoreModule,
        AppRoutingModule 
    ],
    providers       : [],
    bootstrap       : [AppComponent]
})
export class AppModule { 
    constructor(injector:Injector){        
        AppInjector.setInjector(injector); // Store module's injector in the AppInjector class
    }
}
